/**
 * File responsible for all the UI and actions for Settings>Company page - Contact tab item - `/app/settings/company/contact`.
 */

import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    Row,
    Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { History as IHistory } from 'history';
import { forEach, get, isEqual, sortBy } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { forwardRef, Ref, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { ApplicationState } from '../../../../store';
import { saveUserCompanyRequestAction } from '../../../../store/companies/actions';
import { Company, CompanyContact } from '../../../../store/companies/types';
import { removeSpaces } from '../../../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../../utils/commonInterfaces';

const { Title } = Typography;
const { Item: FormItem } = Form;

interface IProps {
    history: typeof IHistory;
    disabled: boolean;
    selectedUserCompany: Company;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
    setFormHasChanges: () => void;
}

const companyContactTypeList = ['Account', 'AccountsReceivable', 'Business'];
const CompanyContactPage: React.FC<IProps> = forwardRef(
    (
        {
            disabled,
            selectedUserCompany,
            handleModalSaveResponse,
            history,
            setFormHasChanges,
        }: IProps,
        ref: Ref<any>
    ) => {
        const dispatch = useDispatch();
        const languagePack = useSelector((state: ApplicationState) =>
            get(state.companies.selectedUserCompany, 'Company.LanguagePackage')
        );
        const [contactFormState, setContactFormState] = useState<{}>({});
        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Function that checks if there are any changes to the form done.
         * Compared to the original state saved in redux.
         */
        const checkForChanges = () => {
            const formState: DynamicObject = processFormState();
            const constructedState: any = {
                ...selectedUserCompany,
                ...formState,
            };

            const stateNotEqual = !isEqual(
                selectedUserCompany,
                constructedState
            );
            if (stateNotEqual) {
                const contactTypes = selectedUserCompany.CompanyContacts;
                const sortedContactOptions = sortBy(
                    contactTypes,
                    (item: DynamicObject) => {
                        return companyContactTypeList.indexOf(
                            get(item, 'Type')
                        );
                    }
                );
                const selectedUserCompanyClone = { ...selectedUserCompany };
                selectedUserCompanyClone.CompanyContacts = sortedContactOptions;

                return !isEqual(selectedUserCompanyClone, constructedState);
            }

            return stateNotEqual;
        };

        /**
         * Function that resets all the changes and set the data
         * equal to the one saved in redux.
         */
        const syncStateFromRedux = () => {
            const {
                Name,
                AddressLine1,
                AddressLine2,
                Suburb,
                State,
                Postcode,
                PhoneNumber,
                Email,
                ABN,
                CompanyContacts,
            } = selectedUserCompany;

            const companyContactObjectList: DynamicObject = {};

            forEach(CompanyContacts, (contact: CompanyContact) => {
                const contactTypeName = removeSpaces(contact.Type);
                companyContactObjectList[`${contactTypeName}ContactName`] =
                    contact.Name;
                companyContactObjectList[`${contactTypeName}ContactEmail`] =
                    contact.Email;
                companyContactObjectList[
                    `${contactTypeName}ContactPhoneNumber`
                ] = contact.PhoneNumber;
            });

            const newState = {
                Name,
                AddressLine1,
                AddressLine2,
                Suburb,
                State,
                Postcode,
                PhoneNumber,
                Email,
                ABN,
                ...companyContactObjectList,
            };

            updateFormStateObject(newState);
        };

        useEffect(syncStateFromRedux, [selectedUserCompany]);

        /**
         * Function for handling the save functionality.
         */
        const handleSave = () => {
            const formState: DynamicObject = processFormState();

            formState.DetailType = 'Contact';
            dispatch(
                saveUserCompanyRequestAction(formState, handleModalSaveResponse)
            );
        };

        /**
         * Function that processes the values from state
         * to be used as payload when submitting the API request.
         */
        const processFormState = () => {
            const newState: DynamicObject = { ...contactFormState };

            const companyContactList: DynamicObject = [];
            forEach(companyContactTypeList, (contactType: string) => {
                const contactTypeName = removeSpaces(contactType);
                delete newState[`${contactTypeName}ContactName`];
                delete newState[`${contactTypeName}ContactPhoneNumber`];
                delete newState[`${contactTypeName}ContactEmail`];
                const nameValue = get(
                    contactFormState,
                    `${contactTypeName}ContactName`
                );
                const phoneNumberValue = get(
                    contactFormState,
                    `${contactTypeName}ContactPhoneNumber`
                );
                const emailValue = get(
                    contactFormState,
                    `${contactTypeName}ContactEmail`
                );
                if (
                    nameValue ||
                    phoneNumberValue ||
                    emailValue
                ) {
                    companyContactList.push({
                        Name: nameValue,
                        PhoneNumber: phoneNumberValue,
                        Email: emailValue,
                        Type: contactType,
                    });
                }
            });

            newState.CompanyContacts = companyContactList;

            return newState;
        };

        /**
         * Function called when Cancel button is clicked from parent.
         */
        const handleCancel = () => {
            syncStateFromRedux();
        };

        /**
         * Common function for updating the form state object
         * created to minimize repitition when updating form state.
         * @param formStateObject
         */
        const updateFormStateObject = (formStateObject: {}) => {
            setContactFormState(formStateObject);
            setFormHasChanges();
        };

        /**
         * Function that updates the form state and which form field will be updated.
         * @param name - field name
         * @param valueIdentifier - string, either value or checked, or others, based on e.target.value
         */
        const updateFormState: any = (
            name: string,
            valueIdentifier: string = 'value'
        ) => {
            return (
                e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent
            ) => {
                const correctValue =
                    get(e, `target.${valueIdentifier}`) ||
                    get(e, `target.${valueIdentifier}`);

                updateFormStateObject({
                    ...contactFormState,
                    [name]: correctValue,
                });
            };
        };

        /**
         * Function that handles copying of state from business contact state values.
         * @param variablePrefix - which state value prefix to copy the state values to
         */
        const copyFromBusinessContact = (variablePrefix: string) => {
            return () => {
                const contactVariables = {
                    [`${variablePrefix}Name`]: get(
                        contactFormState,
                        'BusinessContactName',
                        undefined
                    ),
                    [`${variablePrefix}PhoneNumber`]: get(
                        contactFormState,
                        'BusinessContactPhoneNumber',
                        undefined
                    ),
                    [`${variablePrefix}Email`]: get(
                        contactFormState,
                        'BusinessContactEmail',
                        undefined
                    ),
                };

                updateFormStateObject({
                    ...contactFormState,
                    ...contactVariables,
                });
            };
        };

        /**
         * Responsible for populating the Accounts Receivable card section.
         * Created as a function to easily reuse when handling screen resizing.
         */
        const accountsReceivableCard = (
            <Card className="card-rounded-border">
                <Row>
                    <Col span={24}>
                        <Title level={4}>Accounts Receivable Contact</Title>
                    </Col>
                    <Col span={24} className="grey">
                        This person who is contacted by the collection company
                        to discuss the invoices referred to them. We also send
                        system usage emails to this contact.
                    </Col>
                </Row>
                <br />
                <Row className="mt-10">
                    <Col span={24}>
                        <Form
                            className="company-form"
                            labelCol={{
                                xxl: { span: 5 },
                                xl: { span: 10 },
                                lg: { span: 6 },
                            }}
                            wrapperCol={{
                                xxl: { span: 19 },
                                xl: { span: 14 },
                                lg: { span: 18 },
                            }}
                        >
                            <Row>
                                <Col md={24} sm={24}>
                                    <FormItem label="Name">
                                        <Input
                                            readOnly={disabled}
                                            value={get(
                                                contactFormState,
                                                'AccountsReceivableContactName',
                                                undefined
                                            )}
                                            onChange={updateFormState(
                                                'AccountsReceivableContactName'
                                            )}
                                        />
                                    </FormItem>
                                    <FormItem label="Phone number">
                                        <Input
                                            readOnly={disabled}
                                            value={get(
                                                contactFormState,
                                                'AccountsReceivableContactPhoneNumber',
                                                undefined
                                            )}
                                            onChange={updateFormState(
                                                'AccountsReceivableContactPhoneNumber'
                                            )}
                                        />
                                    </FormItem>
                                    <FormItem label="Email">
                                        <Input
                                            readOnly={disabled}
                                            value={get(
                                                contactFormState,
                                                'AccountsReceivableContactEmail',
                                                undefined
                                            )}
                                            onChange={updateFormState(
                                                'AccountsReceivableContactEmail'
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row type="flex" align="middle" gutter={10}>
                                <Col
                                    xxl={12}
                                    xl={24}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="ta-center"
                                ></Col>
                                <Col
                                    xxl={12}
                                    xl={24}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="ta-right mt-10-xl-range"
                                >
                                    <Button
                                        type="primary"
                                        block
                                        disabled={disabled}
                                        onClick={copyFromBusinessContact(
                                            'AccountsReceivableContact'
                                        )}
                                    >
                                        Copy from business contact
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        );

        return (
            <Col span={24}>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container">
                        <Col span={24}>
                            <Title level={3}>Contact</Title>
                        </Col>
                    </Row>
                    <br />
                    <Row key="company-contact-container">
                        <Col span={24}>
                            <Card className="card-rounded-border">
                                <Row>
                                    <Col span={24}>
                                        <Title level={4}>Company Contact</Title>
                                    </Col>
                                    <Col span={24} className="grey">
                                        This information appears on notification
                                        we send on your behalf
                                    </Col>
                                </Row>
                                <Row className="mt-10">
                                    <Col span={24}>
                                        <Form
                                            className="company-form"
                                            labelCol={{
                                                xxl: { span: 5 },
                                                xl: { span: 10 },
                                                lg: { span: 6 },
                                            }}
                                            wrapperCol={{
                                                xxl: { span: 19 },
                                                xl: { span: 14 },
                                                lg: { span: 18 },
                                            }}
                                        >
                                            <Row gutter={10}>
                                                <Col xl={12} sm={24}>
                                                    <FormItem label="Company name">
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'Name',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'Name'
                                                            )}
                                                        />
                                                    </FormItem>
                                                    <FormItem label="Address line 1">
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'AddressLine1',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'AddressLine1'
                                                            )}
                                                        />
                                                    </FormItem>
                                                    <FormItem label="Address line 2">
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'AddressLine2',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'AddressLine2'
                                                            )}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        label={get(
                                                            languagePack,
                                                            'Suburb',
                                                            'Suburb'
                                                        )}
                                                    >
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'Suburb',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'Suburb'
                                                            )}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        label={get(
                                                            languagePack,
                                                            'State',
                                                            'State'
                                                        )}
                                                    >
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'State',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'State'
                                                            )}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        label={get(
                                                            languagePack,
                                                            'Postcode',
                                                            'Postcode'
                                                        )}
                                                    >
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'Postcode',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'Postcode'
                                                            )}
                                                        />
                                                    </FormItem>
                                                </Col>
                                                <Col xl={12} sm={24}>
                                                    <FormItem label="Phone number">
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'PhoneNumber',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'PhoneNumber'
                                                            )}
                                                        />
                                                    </FormItem>
                                                    <FormItem label="Email">
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'Email',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'Email'
                                                            )}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        label={get(
                                                            languagePack,
                                                            'CompanyRegistrationNumber',
                                                            'ABN'
                                                        )}
                                                    >
                                                        <Input
                                                            readOnly={disabled}
                                                            value={get(
                                                                contactFormState,
                                                                'ABN',
                                                                undefined
                                                            )}
                                                            onChange={updateFormState(
                                                                'ABN'
                                                            )}
                                                        />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-10"></Col>
                    </Row>
                    <Row
                        key="business-contact-container mb-10"
                        gutter={{ lg: 10 }}
                    >
                        <Col xl={12} lg={24}>
                            <Row gutter={{ lg: 10 }}>
                                <Col xl={24} lg={24}>
                                    <Card className="card-rounded-border mb-10">
                                        <Row>
                                            <Col span={24}>
                                                <Title level={4}>
                                                    Business Contact
                                                </Title>
                                            </Col>
                                            <Col span={24} className="grey">
                                                This person we, and authorised
                                                3rd parties, contact to discuss
                                                features and services
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row className="mt-10">
                                            <Col span={24}>
                                                <Form
                                                    className="company-form"
                                                    labelCol={{
                                                        xxl: { span: 5 },
                                                        xl: { span: 10 },
                                                        lg: { span: 6 },
                                                    }}
                                                    wrapperCol={{
                                                        xxl: { span: 19 },
                                                        xl: { span: 14 },
                                                        lg: { span: 18 },
                                                    }}
                                                >
                                                    <Row>
                                                        <Col md={24} sm={24}>
                                                            <FormItem label="Name">
                                                                <Input
                                                                    readOnly={
                                                                        disabled
                                                                    }
                                                                    value={get(
                                                                        contactFormState,
                                                                        'BusinessContactName',
                                                                        undefined
                                                                    )}
                                                                    onChange={updateFormState(
                                                                        'BusinessContactName'
                                                                    )}
                                                                />
                                                            </FormItem>
                                                            <FormItem label="Phone number">
                                                                <Input
                                                                    readOnly={
                                                                        disabled
                                                                    }
                                                                    value={get(
                                                                        contactFormState,
                                                                        'BusinessContactPhoneNumber',
                                                                        undefined
                                                                    )}
                                                                    onChange={updateFormState(
                                                                        'BusinessContactPhoneNumber'
                                                                    )}
                                                                />
                                                            </FormItem>
                                                            <FormItem label="Email">
                                                                <Input
                                                                    readOnly={
                                                                        disabled
                                                                    }
                                                                    value={get(
                                                                        contactFormState,
                                                                        'BusinessContactEmail',
                                                                        undefined
                                                                    )}
                                                                    onChange={updateFormState(
                                                                        'BusinessContactEmail'
                                                                    )}
                                                                />
                                                            </FormItem>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xl={0} lg={24}>
                                    {accountsReceivableCard}
                                </Col>
                                <Col xl={24} lg={24} className="mt-10-lg">
                                    <Card className="card-rounded-border">
                                        <Row>
                                            <Col span={24}>
                                                <Title level={4}>
                                                    Account Contact
                                                </Title>
                                            </Col>
                                            <Col span={24} className="grey">
                                                This person we send our tax
                                                invoices to, for use of IODM's
                                                services
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row className="mt-10">
                                            <Col span={24}>
                                                <Form
                                                    className="company-form"
                                                    labelCol={{
                                                        xxl: { span: 5 },
                                                        xl: { span: 10 },
                                                        lg: { span: 6 },
                                                    }}
                                                    wrapperCol={{
                                                        xxl: { span: 19 },
                                                        xl: { span: 14 },
                                                        lg: { span: 18 },
                                                    }}
                                                >
                                                    <Row>
                                                        <Col md={24} sm={24}>
                                                            <FormItem label="Name">
                                                                <Input
                                                                    readOnly={
                                                                        disabled
                                                                    }
                                                                    value={get(
                                                                        contactFormState,
                                                                        'AccountContactName',
                                                                        undefined
                                                                    )}
                                                                    onChange={updateFormState(
                                                                        'AccountContactName'
                                                                    )}
                                                                />
                                                            </FormItem>
                                                            <FormItem label="Phone number">
                                                                <Input
                                                                    readOnly={
                                                                        disabled
                                                                    }
                                                                    value={get(
                                                                        contactFormState,
                                                                        'AccountContactPhoneNumber',
                                                                        undefined
                                                                    )}
                                                                    onChange={updateFormState(
                                                                        'AccountContactPhoneNumber'
                                                                    )}
                                                                />
                                                            </FormItem>
                                                            <FormItem label="Email">
                                                                <Input
                                                                    readOnly={
                                                                        disabled
                                                                    }
                                                                    value={get(
                                                                        contactFormState,
                                                                        'AccountContactEmail',
                                                                        undefined
                                                                    )}
                                                                    onChange={updateFormState(
                                                                        'AccountContactEmail'
                                                                    )}
                                                                />
                                                            </FormItem>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xxl={{
                                                                span: 12,
                                                                offset: 12,
                                                            }}
                                                            xl={{
                                                                span: 24,
                                                                offset: 0,
                                                            }}
                                                            lg={{
                                                                span: 12,
                                                                offset: 12,
                                                            }}
                                                            md={{
                                                                span: 12,
                                                                offset: 12,
                                                            }}
                                                            sm={{
                                                                span: 12,
                                                                offset: 12,
                                                            }}
                                                            xs={{
                                                                span: 12,
                                                                offset: 12,
                                                            }}
                                                            className="ta-right mt-10-xl"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                block
                                                                disabled={
                                                                    disabled
                                                                }
                                                                onClick={copyFromBusinessContact(
                                                                    'AccountContact'
                                                                )}
                                                            >
                                                                Copy from
                                                                business contact
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={12} lg={0}>
                            {accountsReceivableCard}
                        </Col>
                    </Row>
                </QueueAnim>
            </Col>
        );
    }
);

export default CompanyContactPage;
