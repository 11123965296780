import API, { graphqlOperation } from '@aws-amplify/api';
import { get, map } from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { DETAILS_TAB, PAYMENTS_PAGE } from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    formatDateToDateObjectUTC,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { getCurrentUser } from '../users/sagas';
import {
    addPaymentForADVAMRequestAction,
    addPaymentForEziDebitRequestAction,
    addPaymentForIntegraPayRequestAction,
    addPaymentForUrlRequestAction,
    addPaymentForWesternUnionRequestAction,
    getPaymentChangesErrorAction,
    getPaymentChangesSuccessAction,
    getPaymentConversationErrorAction,
    getPaymentConversationSuccessAction,
    getPaymentDataErrorAction,
    getPaymentDataSuccessAction,
    getPaymentInvoicesErrorAction,
    getPaymentInvoicesSuccessAction,
    getPaymentsErrorAction,
    getPaymentsRequestAction,
    getPaymentsSuccessAction,
    setPaymentSelectedIdSuccessAction,
    deallocatePaymentRequestAction,
} from './actions';
import { PaymentsActionTypes } from './types';

export const getPaymentData = (state: ApplicationState) =>
    state.payments.activeData;

export const getPaymentSelectedId = (state: ApplicationState) =>
    state.payments.activeData.selectedId;

export const getPaymentsPageData = (state: ApplicationState) =>
    state.payments.pageData;

let refetchCount = 0;
/**
 * Function that calls the API for fetching the payment list.
 * @param param0
 */
function* handleGetPaymentsRequest({ payload }: any) {
    const errorMessage =
        'Error fetching payments list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENTS_FOR_COMPANY, {
                ...cleanFilters,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * PAYMENTS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const { Payments } = get(res.data, 'GetPaymentsForCompany');
        const responsePayload = {
            data: Payments,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(Payments.length < pageSize) &&
                    !(pageSize < PAYMENTS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getPaymentsSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getPaymentsRequestAction(payload));
        } else {
            yield put(getPaymentsErrorAction([errorMessage]));
        }
    }
}

/**
 * Function calling the API for fetching the payment data based on the given id.
 * @param param0
 */
function* handleGetPaymentDataRequest({
    payload: { paymentId, isUsingCloudImportType },
}: any) {
    const errorMessage =
        'Error fetching payment details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENT_DETAILS_FOR_COMPANY, {
                PaymentId: paymentId,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const Payment = get(res.data, 'GetPaymentDetailsForCompany');

        if (Payment) {
            const responsePayload = {
                record: Payment,
            };

            yield put(getPaymentDataSuccessAction(responsePayload));
        } else {
            yield put(getPaymentDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected paymentId id for reference.
 * @param param0
 */
function* handleSetPaymentSelectedIdRequest({ payload }: any) {
    const { paymentId, callback } = payload;
    yield put(setPaymentSelectedIdSuccessAction(paymentId));
    if (callback) callback();
}

/**
 * Function that gets the invoices list for a certain payment.
 * @param param0
 */
function* handleGetPaymentInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching payment's invoice list. Please try again later.`;
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Invoices.length < pageSize),
                },
            };

            yield put(getPaymentInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given payment.
 * @param param0
 */
function* handleGetPaymentConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching payment's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getPaymentConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the paymentChanges list of a given payment.
 * @param param0
 */
function* handleGetPaymentChangesRequest({ payload }: any) {
    const errorMessage = `Error fetching payment changes list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CHANGE_LINES_FOR_COMPANY, {
                ...cleanFilters,
                PageSize: pageSize,
                Skip:
                    currentPage * DETAILS_TAB.PAYMENT_CHANGES_TIMELINE.pageSize,
            })
        );

        const { ChangeLines } = get(res.data, 'GetChangeLinesForCompany');

        // const res = yield call(
        //     [API, 'graphql'],
        //     graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
        //         ...cleanFilters,
        //         SortField: sortBy,
        //         Ascending: sortAscending,
        //         PageSize: pageSize,
        //         Skip:
        //             currentPage * DETAILS_TAB.PAYMENT_CHANGES_TIMELINE.pageSize,
        //     })
        // );

        // const { ConversationLines: PaymentChanges } = get(
        //     res.data,
        //     'GetConversationLinesForCompany'
        // );

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.PAYMENT_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getPaymentChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentChangesErrorAction([errorMessage]));
    }
}

/**
 * Function for adding comment to the payment's conversation list.
 * @param param0
 */
function* handlePaymentAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, paymentIds, excludePayments, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(filter, true);

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }
    const payload = {
        PaymentManagementFilter: {
            ...cleanFilters,
            PaymentIds: paymentIds,
            ExcludePayments: excludePayments,
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/paymentcomment',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (paymentIds.length === 1 && excludePayments === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const paymentsUpdated: DynamicObject[] = map(
                    paymentIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getPaymentsPageData);
                yield put(
                    getPaymentsSuccessAction({
                        data: paymentsUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when connecting add payment for payment url to the API.
 * @param param0
 */
function* handleAddPaymentForUrlRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/fromurl',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForUrlRequestAction(payload, callback));
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for western union to the API.
 * @param param0
 */
function* handleAddPaymentForWesternUnionRequest({
    payload: sagaPayload,
}: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/westernunion',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                addPaymentForWesternUnionRequestAction(payload, callback)
            );
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for EziDebit to the API.
 * @param param0
 */
function* handleAddPaymentForEziDebitRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/ezidebit',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForEziDebitRequestAction(payload, callback));
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for IntegraPay to the API.
 * @param param0
 */
function* handleAddPaymentForIntegraPayRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/integrapay',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForIntegraPayRequestAction(payload, callback));
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for ADVAM to the API.
 * @param param0
 */
function* handleAddPaymentForADVAMRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/advam',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForADVAMRequestAction(payload, callback));
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for western union to the API.
 * @param param0
 */
function* handleAddPaymentForCorpayRequest({
    payload: sagaPayload,
}: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/corpay/instructdeal',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        // if (
        //     refetchCount <= maxAPIRefetchCount &&
        //     checkShouldRequestRefetch(err)
        // ) {
        //     refetchCount++;
        //     yield delay(refetchAPIDelay);
        //     yield put(
        //         addPaymentForWesternUnionRequestAction(payload, callback)
        //     );
        // } else {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        // }
    }
}

/**
 * Function called when getting the spot rate for corpay from the API .
 * @param param0
 */
function* handleGetCorpayPaymentSpotRate({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/corpay/spotrate',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        // callback(err)
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        // if (
        //     refetchCount <= maxAPIRefetchCount &&
        //     checkShouldRequestRefetch(err)
        // ) {
        //     refetchCount++;
        //     yield delay(refetchAPIDelay);
        //     yield put(addPaymentForADVAMRequestAction(payload, callback));
        // } else {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        // }
    }

}

/**
 * Function called when connecting book deal for Corpay to the API.
 * @param param0
 */
function* handleCorpayBookDealRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/corpay/bookdeal',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        // callback(err)
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
    }

}

/**
 * Function called when connecting book deal for Corpay to the API.
 * @param param0
 */
 function* handleSendPaymentInstructionForCorpayRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/sendreceipt',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        // callback(err)
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
    }

}

/**
 * Function called when connecting deallocate payment to the API.
 * @param param0
 */
function* handleDeallocatePaymentRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/change/payment/reverse',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(deallocatePaymentRequestAction(payload, callback));
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function for check last payment change line that can be deallocated
 * @param param0
 */
function* handleCheckLastPaymentChangeLineRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    const { filters } = payload;

    try {
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true, 'changelines');

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.CHECK_LAST_PAYMENT_CHANGELINES, {
                ...cleanFilters,
            })
        );

        const { IsLastPaymentChangeline } = get(res.data, 'CheckLastPaymentChangeLines');
        const response = {
            IsSuccess: true,
            IsLastPaymentChangeline
        };
        callback(response);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: err.message ? [err.message] : undefined };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the payments custom fields for a company.
 * @param param0
 */
function* handleGetPaymentsCustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENTS_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetPaymentsCustomFieldsForCompany');
        
        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetPaymentsRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENTS_REQUEST,
        handleGetPaymentsRequest
    );
}

function* watchGetPaymentDataRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_DATA_REQUEST,
        handleGetPaymentDataRequest
    );
}

function* watchSetPaymentSelectedIdRequest() {
    yield takeLatest(
        PaymentsActionTypes.SET_PAYMENT_SELECTED_ID_REQUEST,
        handleSetPaymentSelectedIdRequest
    );
}

function* watchGetPaymentInvoicesRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_INVOICES_REQUEST,
        handleGetPaymentInvoicesRequest
    );
}

function* watchGetPaymentConversationRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_CONVERSATION_REQUEST,
        handleGetPaymentConversationRequest
    );
}

function* watchGetPaymentChangesRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_REQUEST,
        handleGetPaymentChangesRequest
    );
}

function* watchPaymentAddCommentRequest() {
    yield takeLatest(
        PaymentsActionTypes.PAYMENT_ADD_COMMENT_REQUEST,
        handlePaymentAddCommentRequest
    );
}

function* watchAddPaymentForUrlRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_URL_REQUEST,
        handleAddPaymentForUrlRequest
    );
}

function* watchAddPaymentForWesternUnionRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_WESTERN_UNION_REQUEST,
        handleAddPaymentForWesternUnionRequest
    );
}

function* watchAddPaymentForEziDebitRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_EZIDEBIT_REQUEST,
        handleAddPaymentForEziDebitRequest
    );
}

function* watchAddPaymentForIntegraPayRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_INTEGRAPAY_REQUEST,
        handleAddPaymentForIntegraPayRequest
    );
}

function* watchAddPaymentForADVAMRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_ADVAM_REQUEST,
        handleAddPaymentForADVAMRequest
    );
}
function* watchAddPaymentForCorpayRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_CORPAY_REQUEST,
        handleAddPaymentForCorpayRequest
    );
}
function* watchGetCorpayPaymentSpotRate() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_SPOT_RATE,
        handleGetCorpayPaymentSpotRate
    );
}
function* watchCorpayBookDealRequest() {
    yield takeLatest(
        PaymentsActionTypes.CORPAY_BOOK_DEAL_REQUEST,
        handleCorpayBookDealRequest
    );
}
function* watchSendPaymentInstructionForCorpayRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.SEND_PAYMENT_INSTRUCTION_FOR_CORPAY_REQUEST,
        handleSendPaymentInstructionForCorpayRequest
    );
}
function* watchDeallocatePaymentRequest() {
    yield takeLatest(
        PaymentsActionTypes.DEALLOCATE_PAYMENT_REQUEST,
        handleDeallocatePaymentRequest
    );
}


function* watchCheckLastPaymentChangeLineRequest() {
    yield takeLatest(
        PaymentsActionTypes.CHECK_LAST_PAYMENT_CHANGELINES_REQUEST,
        handleCheckLastPaymentChangeLineRequest
    );
}

function* watchGetPaymentsCustomFieldsForCompanyRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENTS_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetPaymentsCustomFieldsForCompanyRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* paymentsSaga() {
    yield all([
        fork(watchGetPaymentsRequest),
        fork(watchGetPaymentDataRequest),
        fork(watchSetPaymentSelectedIdRequest),
        fork(watchGetPaymentInvoicesRequest),
        fork(watchGetPaymentConversationRequest),
        fork(watchGetPaymentChangesRequest),
        fork(watchPaymentAddCommentRequest),
        fork(watchAddPaymentForUrlRequestRequest),
        fork(watchAddPaymentForWesternUnionRequestRequest),
        fork(watchAddPaymentForEziDebitRequestRequest),
        fork(watchAddPaymentForIntegraPayRequestRequest),
        fork(watchAddPaymentForADVAMRequestRequest),
        fork(watchGetCorpayPaymentSpotRate),
        fork(watchCorpayBookDealRequest),
        fork(watchAddPaymentForCorpayRequestRequest),
        fork(watchSendPaymentInstructionForCorpayRequestRequest),
        fork(watchDeallocatePaymentRequest),
        fork(watchCheckLastPaymentChangeLineRequest),
        fork(watchGetPaymentsCustomFieldsForCompanyRequest)
    ]);
}

export default paymentsSaga;
