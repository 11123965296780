/**
 * Component for populating the data in public `Notification details`/`Inform Us` panel
 */

import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Form,
    Modal,
    Row,
    Skeleton,
    Table,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    filter,
    find,
    get,
    includes,
    isEmpty,
    isEqual,
    map,
    pull,
    times,
} from 'lodash';
import moment from 'moment-timezone';
import QueryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../components/common/DateFormatHandler';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import { withNumberFormatHandler } from '../../components/common/NumberFormatHandler';
import RaiseTicketCommonFields from '../../components/common/RaiseTicketCommonFields';
import {
    raiseTicketFieldVariables,
    ticketOptionTypes,
    ticketOptionReasons
} from '../../constants/customerAssistanceSortAndFilters';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import { notificationsQueryString } from '../../constants/notifications';
import {
    getTicketOptionsRequestAction,
    raiseQueryRequestAction,
} from '../../store/notifications/actions';
import { getIsMobile, getNotificationType } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly invoices: any[];
    readonly customer: {};
    readonly company: {};
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly selectedRowKeys?: string[];
    readonly location: {
        search: any;
    };
    readonly match: {
        params: {
            notificationId: string;
        };
    };
    readonly selectionDisabled?: boolean;
}

const NotificationDetailsPanel: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    invoices,
    customer,
    company,
    formatCurrency,
    formatDateLocal,
    selectedRowKeys: selectedRowKeysProps,
    location,
    match,
    selectionDisabled
}: IProps) => {
    const [ticketOptions, setTicketOptions] = useState<{
        options: any[];
        loading: boolean;
        customerId: string;
        companyId: string;
    }>({
        options: [],
        loading: false,
        customerId: '',
        companyId: '',
    });

    const notificationType = useMemo(() => getNotificationType(location), [location]);
    const companyId = get(company, 'CompanyId');
    const isMobile = getIsMobile();
    const dispatch = useDispatch();
    const panelTitle = 'Inform us about these invoices';
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
        setFieldsValue,
    } = form;

    /**
     * Function called when drawer or modal is closed.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Listener function for when the panel's visibility changes.
     * Resets the form and selected keys if panel is closed.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
            selectedKeyPropChangesListener();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Common function for setting the ticketOptions state.
     * @param ticketOptionsObject
     */
    const updateTicketOptions = (ticketOptionsObject: {}) => {
        setTicketOptions({
            ...ticketOptions,
            ...ticketOptionsObject,
        });
    };

    /**
     * Function fot geeting the ticket options to be used in populating the dropdown in `Step 1`
     */
    const getTicketOptions = () => {
        if (companyId) {
            updateTicketOptions({
                loading: true,
            });
            dispatch(
                getTicketOptionsRequestAction(companyId, (res: any) => {
                    const { search } = location;
                    const urlQueries = QueryString.parse(search);
                    const ticketOptionsQueryString = get(
                        urlQueries,
                        notificationsQueryString.TICKET_OPTIONS
                    ) as string;
                    let ticketOptions: string[] = [];
                    if (ticketOptionsQueryString) {
                        ticketOptions = ticketOptionsQueryString.split(',');
                    }

                    const filteredRes = filter(res, (r: DynamicObject) => {
                        if (
                            !isEmpty(ticketOptions) &&
                            !includes(ticketOptions, r.Id)
                        ) {
                            return false;
                        }

                        if (notificationType.isNewInvoice && get(r, 'Reason') === ticketOptionReasons.PROMISE_TO_PAY) {
                            return false;
                        }

                        return !r.Hidden;
                    });
                    updateTicketOptions({
                        options: filteredRes,
                        loading: false,
                        customerId: get(customer, 'Id'),
                        companyId,
                    });
                })
            );
        }
    };

    useEffect(getTicketOptions, [companyId]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, { TicketOption, ...values }: any) => {
            if (!err) {
                if (values.PaymentDate)
                    values.PaymentDate = moment(values.PaymentDate).format(
                        dateFormatDDMMMYYYYSpace
                    );

                setSubmitLoading(true);
                const ticketOptionSelected = find(ticketOptions.options, [
                    'Id',
                    TicketOption,
                ]);
                const WorkflowId = get(ticketOptionSelected, 'WorkflowId');
                dispatch(
                    raiseQueryRequestAction({
                        TicketOptionId: TicketOption,
                        WorkflowId,
                        Details: JSON.stringify(values),
                        InvoiceIds: selectedRowKeys,
                        CustomerId: get(customer, 'Id'),
                        CompanyId: companyId,
                        CommunicationId: match.params.notificationId,
                        callback: raiseQueryResponseModal,
                    })
                );
            }
        });
    };

    /**
     * Function for showing the response modal (error/success) after clicking the `Submit` button.
     * @param param0
     */
    const raiseQueryResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Ticket sent successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to send ticket!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }
    };

    /**
     * Listener function for changes in toggled checkbox for invoice list.
     */
    const selectedKeyPropChangesListener = () => {
        setSelectedRowKeys(selectedRowKeysProps);
    };

    useEffect(selectedKeyPropChangesListener, [selectedRowKeysProps]);

    const columns = isMobile
        ? [
              {
                  title: '',
                  dataIndex: 'NumberXS',
              },
              {
                  title: '',
                  dataIndex: 'AmountOwing',
              },
          ]
        : [
              {
                  title: 'Invoice No',
                  dataIndex: 'Number',
              },
              {
                  title: 'Amount Owing',
                  dataIndex: 'AmountOwing',
              },
              {
                  title: 'Due Date',
                  dataIndex: 'DueDate',
              },
          ];

    const dataSource = map(invoices, ({ Id, ...data }: any) => {
        const newSelectedRowKeys = [...selectedRowKeys];
        const isChecked = includes(selectedRowKeys, Id);
        return {
            ...data,
            key: Id,
            NumberXS: (
                <div>
                    Invoice No &nbsp;&nbsp;
                    <Checkbox
                        disabled={selectionDisabled}
                        checked={isChecked}
                        onChange={(e: any) => {
                            if (e.target.checked) {
                                newSelectedRowKeys.push(Id);
                            } else {
                                pull(newSelectedRowKeys, Id);
                            }

                            setSelectedRowKeys(newSelectedRowKeys);
                        }}
                    ></Checkbox>
                    &emsp;
                    {data.Number}
                </div>
            ),
            AmountOwing: formatCurrency
                ? formatCurrency(data.AmountOwing)
                : data.AmountOwing,
            // DueDate: formatDateUTCToLocal(
            //     data.DueDate,
            //     null,
            //     dateFormatDDMMMYYYYSpace
            // ),
            DueDate: formatDateLocal(data.LocalDueDate),
        };
    });

    /**
     * Function called when ticking the checkbox in the table header.
     * @param e
     */
    const selectAllInvoices = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const keys: string[] = map(invoices, 'Id');
            setSelectedRowKeys(keys);
        } else {
            setSelectedRowKeys([]);
        }
    };

    const isAllChecked = isEqual(selectedRowKeys, map(invoices, 'Id'));

    const rowSelection = isMobile
        ? undefined
        : {
              selectedRowKeys: selectedRowKeys,
              onChange: (newSelectedRowKeys: any) => {
                  setSelectedRowKeys(newSelectedRowKeys);
              },
              getCheckboxProps: (record: any) => ({
                  disabled: selectionDisabled
              }),
          };

    /**
     * Function for getting if the selected ticket option type is ticket only.
     */
    const getIsInfoOnlyTicketOptionType = () => {
        const selectedTicketId =
            getFieldValue(raiseTicketFieldVariables.SELECT_ACTION) ||
            get(ticketOptions.options, '0.Id');
        const selectedTicketOption = get(
            filter(ticketOptions.options, ['Id', selectedTicketId]),
            '0'
        );
        return (
            get(selectedTicketOption, 'Type') === ticketOptionTypes.INFO_ONLY
        );
    };

    /**
     * Function for populating the panel content (with loading skeletons/data).
     */
    const populatePanelContent = () => {
        if (ticketOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            return (
                <Form className="form-inline-mb-0">
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <b>Step 1.</b>
                                </Col>
                            </Row>
                            <RaiseTicketCommonFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldValue={getFieldValue}
                                setFieldsValue={setFieldsValue}
                                isMobile={isMobile}
                                ticketOptions={ticketOptions}
                            />
                        </Col>
                    </Row>
                    {getIsInfoOnlyTicketOptionType() === false && (
                        <Row className="mt-10">
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <b>Step 2.</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Confirm the invoices in this information
                                    </Col>
                                </Row>
                                <Row className="mt-10">
                                    <Col>
                                        {isMobile && (
                                            <Checkbox
                                                disabled={selectionDisabled}
                                                checked={isAllChecked}
                                                onChange={selectAllInvoices}
                                            >
                                                All
                                            </Checkbox>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table
                                            className="themed-table"
                                            showHeader={!isMobile}
                                            columns={columns}
                                            dataSource={dataSource}
                                            rowSelection={rowSelection}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Form>
            );
        }
    };

    return (
        <Row>
            <Col>
                {isMobile ? (
                    <div>
                        {visible && (
                            <Modal
                                className="modal-swapped-buttons modal-no-padding"
                                title={panelTitle}
                                visible={visible}
                                onOk={handleSubmitForm}
                                onCancel={handleClosePanel}
                                okButtonProps={
                                    getIsInfoOnlyTicketOptionType()
                                        ? undefined
                                        : {
                                              disabled:
                                                  ticketOptions.loading ||
                                                  isEmpty(
                                                      ticketOptions.options
                                                  ),
                                          }
                                }
                                okText="Submit"
                                style={{ top: 12 }}
                            >
                                <div
                                    style={{
                                        maxHeight: window.innerHeight - 160,
                                        overflowY: 'auto',
                                        padding: 24,
                                    }}
                                >
                                    {populatePanelContent()}
                                </div>
                            </Modal>
                        )}
                    </div>
                ) : (
                    <Drawer
                        title={panelTitle}
                        width={500}
                        onClose={handleClosePanel}
                        visible={visible}
                        className="notification-details-panel"
                        closable={false}
                        maskClosable={false}
                    >
                        {visible && (
                            <>
                                <div>{populatePanelContent()}</div>
                                <br />
                                <Row>
                                    <Col className="ta-right" span={24}>
                                        {getIsInfoOnlyTicketOptionType() ===
                                            false && (
                                            <Button
                                                className="mr-8"
                                                type="primary"
                                                onClick={handleSubmitForm}
                                                disabled={
                                                    ticketOptions.loading ||
                                                    isEmpty(
                                                        ticketOptions.options
                                                    )
                                                }
                                            >
                                                Submit
                                            </Button>
                                        )}

                                        <Button onClick={handleClosePanel}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Drawer>
                )}
            </Col>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle="Sending ticket"
                    modalVisible={submitLoading}
                    displayMessage="Please wait while sending the ticket . . ."
                />
            )}
        </Row>
    );
};

const NotificationDetailsPanelForm = Form.create({
    name: 'notification-details-panel-form',
})(NotificationDetailsPanel);

export default withRouter(
    withNumberFormatHandler(withDateFormatHandler(NotificationDetailsPanelForm))
);
