/**
 * File responsible for the content when clicking `Cancel plan` in management pages.
 */

import { Button, Checkbox, Col, Drawer, Form, Modal, Row, Table } from 'antd';
import { capitalize, filter, first, get, isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, roundNumberToDecimalDigits } from '../../../utils/commonFunctions';
import moment from 'moment-timezone';
import { dateFormatDDMMMYYYYSpace } from '../../../constants/dateFormats';
import { RemittanceAdvice, RemittanceAdvicesState } from '../../../store/remittanceAdvices/types';
import { getRemittanceAdviceSelectedId, selectRemittanceAdviceData } from '../../../store/remittanceAdvices/sagas';
import { ApplicationState } from '../../../store';
import FormItem from 'antd/lib/form/FormItem';
import { getCustomerUILabel } from '../../../store/customers/sagas';

const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (isPaymentPlanCreatedSuccessfully?: boolean) => void;
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly form?: any;
    readonly remittanceAdvice: RemittanceAdvice[];
    readonly dispatchAction?: (payload: any) => void;
}

interface RemittanceAdviceAllocation {
    Type: string;
    Code: string;
    Customer: string;
    Invoices: string[];
    RemainingAmount: number;
    TotalAllocated: number;
  }

const ReviewRemittanceAdvicePanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    formatCurrency,
    formatToParts,
    form,
    remittanceAdvice,
    dispatchAction,
}: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { validateFields, resetFields, getFieldDecorator } = form;

    const remittanceAdviceAllocation: RemittanceAdviceAllocation[] = useSelector(
        (state: ApplicationState) => state.remittanceAdvices.activeData.Allocation.AllocationItems
    );

    const remittanceAdviceAllocationErrors: string[] = useSelector(
        (state: ApplicationState) => state.remittanceAdvices.activeData.Allocation.AllocationErrors
    );

    const [checked, setChecked] = useState<boolean>(false);
    

    const remittanceAdviceTableColums = [
        {
            title: 'Type',
            dataIndex: 'Type',
        },
        {
            title: 'Number',
            dataIndex: 'Code',
        },
        {
            title: capitalize(customerLabel),
            dataIndex: 'Customer',
        },
        {
            title: 'Invoices',
            dataIndex: 'Invoices',
        },
        {
            title: 'Total allocated',
            dataIndex: 'TotalAllocated',
        },
        {
            title: 'Remaining',
            dataIndex: 'RemainingAmount',
        }
    ];

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            setChecked(false);
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);
                if (dispatchAction) {
                    dispatch(
                        dispatchAction({
                           ...remittanceAdvice,
                           callback: actionResponseModal
                        })
                    );
                } else {
                    if (closePanel) closePanel(true);
                }
            }
        });
    };

    /**
     * Function responsible for showing the response modal after creating the payment plan.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const actionResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Confirmed remittance advice successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to confirm remittance advice!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }
    };

    /**
         * Function called for formatting an amount if formatCurrency HOC function exists.
         * @param amount - number for format
    */
    const handleFormatCurrency = (amount: number) => {
        return {
            amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
            currency: first(map(
                filter(
                    (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                ),
                p => p.value
            ))
        };
    };

    const hasErrors = remittanceAdviceAllocationErrors.length > 0;

    const populateTableDataSourceItem = (remittanceAdviceAllocation: RemittanceAdviceAllocation, index: number ) => {
        const { currency } = handleFormatCurrency(remittanceAdviceAllocation.TotalAllocated);
        const isMultipleCustomer = get(remittanceAdvice, 'MultipleCustomer');

        return {
            Type: remittanceAdviceAllocation.Type,
            Code: (<>
                <div style={{ flexGrow: 1 }} className="mr-5">
                    {remittanceAdviceAllocation.Code}
                </div>
            </>),
            Customer: isMultipleCustomer && remittanceAdviceAllocation.Type == 'Payment'? `Multiple ${capitalize(customerLabel)}s` : remittanceAdviceAllocation.Customer,
            Invoices: remittanceAdviceAllocation.Invoices,
            TotalAllocated: (<>
                {remittanceAdviceAllocation.TotalAllocated > 0 ? 
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {handleFormatCurrency(remittanceAdviceAllocation.TotalAllocated).amountDisplay}
                    </div>
                    :
                    <div style={{ flexGrow: 1, color: "red" }} className="mr-5">
                        {handleFormatCurrency(remittanceAdviceAllocation.TotalAllocated).amountDisplay}
                    </div>
            } 
            </>),
            RemainingAmount: (<>
               {remittanceAdviceAllocation.Type == 'Rebate' && remittanceAdviceAllocation.RemainingAmount > 0 ? 
                    <div style={{ flexGrow: 1, color: "red"  }} className="mr-5">
                        {handleFormatCurrency(remittanceAdviceAllocation.RemainingAmount).amountDisplay}
                    </div>
                    :
                    <div style={{ flexGrow: 1}} className="mr-5">
                        {handleFormatCurrency(remittanceAdviceAllocation.RemainingAmount).amountDisplay}
                    </div>
            } 
            </>)
        }
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {

        const formattedRemittanceAdviceAllocation = remittanceAdviceAllocation.map((ra) => ({
            ...ra,
            Invoices: ra.Invoices.join(', '),
            TotalAllocated: (ra.TotalAllocated).toFixed(2)
        }));

        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24} className="mb-10">
                        <div>
                            <h4>Below is a summary of how each item will be allocated to each invoice.</h4>
                        </div>
                       <div>
                            <h4>Please note the items will be allocated in order of appearance.</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey="Id"
                            className="app-rma-confirm-table"
                            columns={remittanceAdviceTableColums}
                            dataSource={map(formattedRemittanceAdviceAllocation, populateTableDataSourceItem)}
                            pagination={false}
                            bordered={true}
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    const populateErrorContent = () => {
        return (
           <Row className="mb-10" style={{ color: "red" }}>
            <Col>
            Invalid allocations detected. Please reverify your entries on the previous panel.
            </Col>
            <br/>
                {remittanceAdviceAllocationErrors.map((error, index) => (
                    <Col key={index}>
                    {error}
                    </Col>
                ))}
           </Row>
        );
    }

    return (
        <Drawer
            title="Allocation Summary"
            width={1000}
            onClose={handleClosePanel}
            visible={visible}
            className="review-created-new-payment-plan-panel"
            closable={false}
            maskClosable={false}
        >
         <Row>
                <Col>
                    <div>{populatePanelContent()}</div>
                    <br />
                    <Row>
                        {hasErrors && populateErrorContent()}
                    </Row>
                    {!hasErrors && (
                        <Row>
                             <Form labelAlign='left' className="form-inline-mb-8">
                                <FormItem wrapperCol={{ span: 24 }}>
                                            {getFieldDecorator("confirmCheckbox", {
                                                valuePropName: 'checked',
                                                initialValue: false,
                                                onChange: (event: any) => {
                                                    const checked = get(event, 'target.checked');
                                                    if (checked) {
                                                       setChecked(true);
                                                    } else {
                                                        setChecked(false);
                                                    }
                                                }
                                            })(
                                                <Checkbox>I understand that these will be the amounts allocated as per the current state of all items seen in this list.</Checkbox>
                                            )}
                                </FormItem>
                             </Form>
                            
                        </Row>
                    )}
                    <Row>
                        <Col className="ta-right" span={24}>
                            <Button
                                className="mr-8"
                                type="primary"
                                onClick={handleSubmitForm}
                                hidden={hasErrors}
                                disabled={!checked}
                            >
                                Save
                            </Button>
                            <Button className="buttonGrey mr-8" onClick={handleClosePanel}>Back</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle="Confirming remittance advice"
                    modalVisible={submitLoading}
                    displayMessage="Please wait while confirming the remittance advice . . ."
                />
            )}
        </Drawer>
    );
};

const ReviewRemittanceAdvicePanelForm = Form.create({
    name: 'review-created-new-payment-plan-panel-form',
})(ReviewRemittanceAdvicePanel);

export default withRouter(ReviewRemittanceAdvicePanelForm);