/**
 * File for the `Top payments widget.
 */

import { Table } from 'antd';
import {
    clone,
    filter,
    find,
    forEach,
    get,
    includes,
    isEmpty,
    isUndefined,
    map,
    sum,
} from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { populatePayloadForOrganisationRegionalWidgets } from '../../constants/dashboards';

import { hiddenCloudImportFields } from '../../constants/settings';
import { ApplicationState } from '../../store';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getDashboardPaymentsRequestAction } from '../../store/dashboards/actions';

import {
    customFieldsTableColumnValues,
    dashboardBypassAPIFetch,
    getCompanyName,
    replaceInstancesOfCustomerString,
    getDateFilterValues
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    paymentFieldIndicator
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';
import {
    displayColumnIndicator,
    displayColumnOptions,
    displayRowCountOptions,
    hiddenTableColumns,
    showCustomFieldsIndicator,
} from './PaymentsWidgetFields';
import {
    dateSelectOptions
} from '../../constants/invoicesSortAndFilters';
import { Payment } from '../../store/payments/types';
import { paymentsSortByOptions } from '../../constants/paymentsSortAndFilters';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly formatCurrency: (
        amount: number,
        cusCurrencyCode?: string,
        cusLocale?: string
    ) => JSX.Element;
    customFieldsFilterList: DynamicObject[];
    isUsingCloudImportType: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly functionRefObj?: any;
}

const PaymentsWidget: React.FC<IProps> = ({
    widgetDetails,
    formatDateLocal,
    formatCurrency,
    customFieldsFilterList,
    isUsingCloudImportType,
    isOrgView,
    organisationCurrenciesAll,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const usedDisplayColumns: any = isOrgView
        ? displayColumnOptions
        : filter(displayColumnOptions, ['OrgViewOnly', false]);

    const initialTableColumns = filter(usedDisplayColumns, [
        'defaultChecked',
        true,
    ]).map(({ label, value }: DynamicObject) => ({
        title: replaceInstancesOfCustomerString(
            label,
            customerLabel,
            isOrgView
        ),
        dataIndex: value,
    }));

    const dispatch = useDispatch();
    const [tableState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        columns: DynamicObject[];
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        columns: initialTableColumns,
        dataSource: [],
    });

    const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
    const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

    let currencyValueUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        currencyCodeUsed = get(currencyValueUsed, 'Value');
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `tableState` state.
     * @param tableStateObject
     */
    const updateTableStateObject = (tableStateObject: {}) => {
        setTableState({
            ...tableState,
            ...tableStateObject,
        });
    };

    /**
     * Common function for formatting currencies
     */
    const handleFormatCurrency = (toFormat: number) => {
        return formatCurrency(toFormat, currencyCodeUsed, localeUsed);
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const paymentsColumns: DynamicObject[] = [];
        const customFieldFilters: DynamicObject = {};
        const customFieldsDisplayList: any = [];
        let hasDisplayValue = false;
        let amountFilters: any = {
            AmountType: '',
            AmountValue: 0,
            AmountOperator: '',
        };
        let Customer: string = '';
        let paidDateFilters: any = {
            value: undefined,
            From: undefined,
            To: undefined,
            Last: undefined,
            Next: undefined
        }
        let PaidDateMin: any = undefined;
        let PaidDateMax: any = undefined;

        paidDateFilters.value = get(widgetDetails, "PaymentField---PaidDate--DateType");

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');

            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue) &&
                !includes(hiddenTableColumns, fieldName)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        displayColumnOptions,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    paymentsColumns.push({
                        title: replaceInstancesOfCustomerString(
                            columnTitle,
                            customerLabel,
                            isOrgView
                        ),
                        dataIndex: fieldName,
                    });
                }
            }

            if (includes(wdKey, customFieldIndicator) &&
                !includes(wdKey, showCustomFieldsIndicator)
                ) {
                customFieldFilters[wdKey + appliedFilterIndicator] = wdValue;
                
            }

            if (
                includes(wdKey, showCustomFieldsIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const customFieldName = wdKey.replace(`${showCustomFieldsIndicator}${customFieldIndicator}`, '').split("--")[1];
                    const customFieldIndex = wdKey.replace(`${showCustomFieldsIndicator}`, '');
                    const customType = wdKey.replace(`${showCustomFieldsIndicator}${customFieldIndicator}`, '').split("--")[0];

                    const columnTitle: string = customFieldName;
                    paymentsColumns.push({
                        title: replaceInstancesOfCustomerString(
                            columnTitle,
                            customerLabel,
                            isOrgView
                        ),
                        dataIndex: customFieldIndex,
                    });

                    customFieldsDisplayList.push( {
                        type: customType,
                        FieldIndex: customFieldIndex
                    });
                }
            }

            if (includes(wdKey, paymentFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "Amount--AmountType")) {
                    amountFilters.AmountType = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountOperator")) {
                    amountFilters.AmountOperator = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountValue")) {
                    amountFilters.AmountValue = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "PaidDate")) {
                    if (paidDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            paidDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            paidDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (paidDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last")) {
                            paidDateFilters.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(paidDateFilters);
                    PaidDateMin = minDate;
                    PaidDateMax = maxDate;
                }
            }
        });

        const results = map(
            get(widgetDetails, 'PaymentField---Result'),
            (res: string) => parseInt(res)
        );

        const customerCustomFieldsDataIndexes: string[] = [];

        forEach(customFieldsDisplayList, (cfs: DynamicObject) => {
            const Type = get(cfs, 'type');
            const FieldName = get(cfs, 'FieldIndex');
            
            if (Type === CUSTOM_FIELD_TYPES.CUSTOMER) {
                customerCustomFieldsDataIndexes.push(FieldName);
            }
        });

        let payload: DynamicObject = {
            filters: {
                Customer,
                CustomerCode: '',
                PaidDateMin,
                PaidDateMax,
                ...amountFilters,
                ...customFieldFilters,
            },
            Result: sum(results),
            sortBy:  get(widgetDetails, 'SortField') ||
                     get(paymentsSortByOptions[2], 'value'),
            Ascending: get(widgetDetails, 'SortFieldAsc') ||
                     false,
            pageSize:
                get(widgetDetails, 'rowCount') ||
                get(displayRowCountOptions, 0),
            currentPage: 0,
           
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed,
            });
        }

        dispatch(
            getDashboardPaymentsRequestAction(
                payload,
                isOrgView,
                (payments: Payment[]) => {
                    if (unmountedRef.current) return;

                    let usedColumns: any = [];
                    if (hasDisplayValue) {
                        usedColumns = paymentsColumns;
                    } else {
                        usedColumns = tableState.columns;
                    }

                    if (isUsingCloudImportType) {
                        usedColumns = filter(
                            usedColumns,
                            (col) =>
                                !includes(
                                    hiddenCloudImportFields,
                                    col.dataIndex
                                )
                        );
                    }

                    const hasCustomCustomerField = (filter(customFieldsDisplayList,
                        (item) => item.type === "Customer"
                      )).length;

                    const usedDataSource = map(
                        payments,
                        (payment: Payment, paymentIdx: number) => {
                            let allCustomFieldsValues: DynamicObject = {};
                            if (
                                !isEmpty(get(payment, 'Customer.CustomFields')) &&
                                hasCustomCustomerField > 0
                            ) {
                                const customerCustomFields =
                                    customFieldsTableColumnValues(
                                        CUSTOM_FIELD_TYPES.CUSTOMER,
                                        payment.Customer.CustomFields,
                                        customerCustomFieldsDataIndexes
                                    );
                                allCustomFieldsValues = {
                                    ...allCustomFieldsValues,
                                    ...customerCustomFields,
                                };
                            }

                            return {
                                key: paymentIdx,
                                ...payment,
                                PaymentReference: get(payment, 'PaymentReference'),
                                LocalPaidDate: get(payment, 'LocalPaidDate')
                                    ? formatDateLocal(
                                        get(payment, 'LocalPaidDate')
                                    )
                                    : '',
                                LocalSettlementDate: !isUndefined(
                                    get(payment, 'LocalSettlementDate')
                                ) && get(payment, 'LocalSettlementDate') !== ""
                                    ? formatDateLocal(get(payment, 'LocalSettlementDate'))
                                    : '',
                                PaidAmount: !isUndefined(
                                    get(payment, 'PaidAmount')
                                )
                                    ? handleFormatCurrency(
                                        get(payment, 'PaidAmount')
                                    )
                                    : '',
                                Result: get(payment, 'Result'),
                                CustomerCode: get(payment, 'Customer.CustomerCode'),
                                CustomerName: getCompanyName(payment.Customer),
                                CompanyName: get(payment, 'Company.Name'),
                                ...allCustomFieldsValues,
                            };
                        }
                    );

                    updateTableStateObject({
                        columns: usedColumns,
                        dataSource: usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        if (isOrgView && isEmpty(organisationCurrenciesAll)) return;

        const bypassAPIFetching = dashboardBypassAPIFetch(
            tableState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails, organisationCurrenciesAll]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const {
        columns: stateColumns,
        loading: stateLoading,
        dataSource: stateDataSource,
    } = tableState;
    return (
        <div>
            <Table
                className="table-striped-rows table-ws-nw"
                columns={stateColumns}
                dataSource={stateDataSource}
                loading={stateLoading}
                pagination={false}
                size="middle"
            />
        </div>
    );
};

export default withAccountingSystemHandler(
    withDateFormatHandler(withNumberFormatHandler(PaymentsWidget))
);
