/**
 * Component for showing the public payment plan page.
 */

import Amplify from '@aws-amplify/core';
import {
    Button,
    Card,
    Carousel,
    Col,
    Collapse,
    Empty,
    Modal,
    Row,
    Skeleton,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import Cookies from 'js-cookie';
import {
    clone,
    endsWith,
    forEach,
    forIn,
    get,
    includes,
    isEmpty,
    isNull,
    isUndefined,
    lowerCase,
    map,
    orderBy,
    some,
    startsWith,
    toString,
} from 'lodash';
import moment from 'moment-timezone';
import QueryString from 'query-string';
import React, { Fragment, lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { generateAmplifyPublicConfig } from '../../AmplifyConfig';
import { withDateFormatHandler } from '../../components/common/DateFormatHandler';
import { customFieldIndicator } from '../../components/common/FilterBar';
import FontAwesome from '../../components/common/FontAwesome';
import ImageWithLoading from '../../components/common/ImageWithLoading';
import { withNumberFormatHandler } from '../../components/common/NumberFormatHandler';
import {
    COOKIE_NOTIFICATION_DELAY_FETCH,
    DEFAULT_LOCALE,
    DEFAULT_REGION_NAME,
    initialPrimaryColor,
    TOKEN_NAME_EXPIRY,
} from '../../config/config';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    dateFormatNowPlaceholder,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../../constants/dateFormats';
import {
    cardPaymentSystems,
    creditCardOptionsList,
    defaultCreditCardsAvailable,
    defaultDisplay,
    eziDebitPaymentTypes,
    globalPaymentSystems,
    notificationsQueryString,
    paymentOptionsDataMapping,
    supportedWUPaymentProviders,
} from '../../constants/notifications';
import { paymentResults } from '../../constants/paymentsSortAndFilters';
import {
    getRegionKeyConfigAction,
    getRegionSettingsConfigAction,
} from '../../store/auth/actions';
import {
    getRegionKeyConfig,
    getRegionSettingConfig,
} from '../../store/auth/sagas';
import {
    getDemoOptionsConfigAction,
    setLocaleAction,
} from '../../store/common/actions';
import { CustomField } from '../../store/common/types';
import { CompanyCustomFieldConfigure, CompanyNotificationCustomization } from '../../store/companies/types';
import { getNotificationLetterPresignedUrlAction, getCorpayCurrenciesForCompanyRequestAction } from '../../store/notifications/actions';
import { fetchPaymentPlanCommunicationDetail } from '../../store/paymentPlans/actions';
import {
    addPaymentForADVAMRequestAction,
    addPaymentForEziDebitRequestAction,
    addPaymentForIntegraPayRequestAction,
    addPaymentForUrlRequestAction,
    addPaymentForWesternUnionRequestAction,
    addPaymentForCorpayRequestAction,
    sendInstructionPaymentForCorpayRequestAction
} from '../../store/payments/actions';
import {
    AddPaymentForADVAMRequestPayload,
    AddPaymentForEziDebitRequestPayload,
    AddPaymentForIntegraPayRequestPayload,
    AddPaymentForUrlRequestPayload,
    AddPaymentForWesternUnionRequestPayload,
    AddPaymentForCorpayRequestPayload
} from '../../store/payments/types';
import {
    addDaysToDate,
    formatDateToDateObjectUTC,
    getAssetsPath,
    getContrastColor,
    getRegionConfigFromList,
    invalidateImagePathCache,
    openURLNewTab,
    parseToHTML,
    postBypassCORS,
    replaceAllStrDynamic,
    roundNumberToDecimalDigits,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { defaultCardPaymentClientRef } from '../settings/CustomerPaymentsManagementPage';
import './public.less';
import corpayLogo from '../../assets/corpayLogo.png';
import './notification.less';
import { customizationPlaceholders } from '../../constants/settings';
import { defaultGuidValue } from '../../constants/common';
import NotificationDetailsPanel from './NotificationDetailsPanel';
import { getDemoOptionsConfig } from '../../store/common/sagas';
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const NotificationDetailsAttachmentsPanel = lazy(
    () => import('./NotificationDetailsAttachmentsPanel')
);

const NotificationPaymentOptionsPanel = lazy(
    () => import('./NotificationPaymentOptionsPanel')
);

const NotificationEziDebitPaymentOptionsPanel = lazy(
    () => import('./NotificationEziDebitPaymentOptionsPanel')
);

const NotificationAdvamPaymentOptionsPanel = lazy(
    () => import('./NotificationAdvamPaymentOptionsPanel')
);

const NotificationCorpayPaymentAutoPanel = lazy(
    () => import('./NotificationCorpayPaymentAutoPanel')
);

const NotificationCorpaySendInstructionPanel = lazy(
    () => import('./NotificationCorpaySendInstructionPanel')
);

const { Title } = Typography;
const { Panel } = Collapse;

const assetsPath = getAssetsPath();

let isEmailPayNowClicked = false;
let isEmailWesternUnionClicked = false;
let isCorpayClicked = false;

const customerNumberPlaceholder = '[CustomerNumber]';
const customerCodePlaceholder = '[CustomerCode]';
const customerNamePlaceholder = '[CustomerName]';
const invoiceListPlaceholder = '[InvoiceList]';
const paymentTotalPlaceholder = '[PaymentTotal]';
const nowPlaceholder = '[Now]';
const legalVisionInvoiceListPlaceholder = '[LVInvoiceList]';
const storedInvoicesPrefix = 'iodm-invs-stored-';

interface IProps {
    location: any;
    history: {
        push: (path: string) => void;
    };
    match: {
        params: {
            notificationId: string;
        };
    };
    formatCurrency: (amount: number) => JSX.Element;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}

let primaryColorTheme: string | undefined = undefined;
let contrastPrimaryColor: string | undefined = undefined;
let initialLoadingDone = false;

const NotificationDetails: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const informUsPanelRef = useRef<any>(null);
    const corpayPaymentPanelRef = useRef<any>(null);
    const corpaySendInstructionPanelRef = useRef<any>(null);
    const [paymentScheduleDetails, setPaymentScheduleDetails] = useState<{
        loading: boolean;
        Invoices: any[];
        Customer: {
            Contacts: [
                {
                    Email: string
                }
            ];
        };
        Company: {};
        PaymentPlanSchedule: {};
        hasError: boolean;
        errorMessages: string[];
    }>({
        loading: true,
        Invoices: [],
        Customer: {
            Contacts: [
                {
                    Email: ''
                }
            ]
        },
        Company: {},
        PaymentPlanSchedule: {},
        hasError: false,
        errorMessages: [],
    });

    const [informUsVisible, setInformUsVisible] = useState<boolean>(false);

    const [customization, isTopBranding, isBottomBranding]: [CompanyNotificationCustomization, boolean, boolean] = useMemo(() => {
        let customization = get(
            paymentScheduleDetails,
            'Company.NotificationCustomization'
        );
        if (!customization) {
            customization = defaultDisplay;
        }

        const brandingLocation = get(customization, 'BrandingLocation') || defaultDisplay.BrandingLocation;
        const isTop = startsWith(brandingLocation, 'top');
        return [customization, isTop, !isTop];
    }, [paymentScheduleDetails]);

    const [usedAmplifyConfig, setUsedAmplifyConfig] = useState<
        DynamicObject | undefined
    >(undefined);

    const [attachmentsState, setAttachmentsState] = useState<{
        showPanel: boolean;
        selectedRecordForAttachment: DynamicObject | null;
    }>({
        showPanel: false,
        selectedRecordForAttachment: null,
    });

    const [showPaymentOptionsPanel, setShowPaymentOptionsPanel] =
        useState<boolean>(false);

    const [eziDebitState, setEziDebitState] = useState<{
        showPanel: boolean;
        isConfirmation: boolean;
    }>({
        showPanel: false,
        isConfirmation: false,
    });

    const [advamState, setAdvamState] = useState<{
        showPanel: boolean;
        iframeURL: string | undefined;
    }>({
        showPanel: false,
        iframeURL: undefined,
    });

    const [corpayPaymentState, setCorpayPaymentState] = useState<{
        showCorpayPaymentSelectAutoPanel: boolean;
    }>({
        showCorpayPaymentSelectAutoPanel: false,
    });

    const [spotRateDetails, setSpotRateDetails] = useState<{
        spotRate: any,
        amountToBePaid: number,
        amountToBeSettled: any
    }>({
        spotRate: 0,
        amountToBePaid: 0,
        amountToBeSettled: 0
    });

    const paymentOptionsData = get(
        paymentScheduleDetails,
        'Company.PaymentOptions',
        {}
    );
    const [corpayCurrencies, setCorpayCurrencies] = useState<string[]>([]);
    const languageCode: string = get(
        paymentScheduleDetails,
        'Company.LanguagePackage.Language',
        DEFAULT_LOCALE
    );

    const countryCodeAlpha2 = useMemo(() => {
        if (!languageCode) return null;
        const countryCode = languageCode.substring(3).toUpperCase();
        switch (countryCode) {
            case 'GB': return 'UK';
            default: return countryCode;
        }
    }, [languageCode]);

    const [addPaymentLoading, setAddPaymentLoading] = useState<boolean>(false);

    const regionSettingsConfig = useSelector(getRegionSettingConfig);
    const regionKeyConfig = useSelector(getRegionKeyConfig);

    const [preparingFileLink, setPreparingFileLink] = useState<{
        loading: boolean;
        isLetter: boolean;
    }>({
        loading: false,
        isLetter: true,
    });

    const [tableSelection, setTableSelection] = useState<{
        selectedRowKeys: string[];
        totalPayment: number;
    }>({
        selectedRowKeys: [],
        totalPayment: 0,
    });

    const [corpayInstructPaymentDetails, setCorpayInstructPaymentDetails] = useState<{
        OrderId: string,
        AmountToBeSettled: number,
        CustomerPaidAmount: number,
        ClientAccessToken: string,
        SelectedCurrency: string
    }>({
        OrderId: '',
        AmountToBeSettled: 0,
        CustomerPaidAmount: 0,
        ClientAccessToken: '',
        SelectedCurrency: ''
    });

    const [corpaySendInstructionState, setCorpaySendInstructionState] = useState<{
        showCorpaySendInstructionPanel: boolean;
        paymentId: string;
    }>({
        showCorpaySendInstructionPanel: false,
        paymentId: ''
    });

    const paymentScheduleTotal = get(
        paymentScheduleDetails,
        'PaymentPlanSchedule.AmountRemaining',
        0
    );

    /**
     * Get region configs.
     */
    const initializeSettingsConfig = () => {
        dispatch(getRegionSettingsConfigAction());
        dispatch(getRegionKeyConfigAction());

        dispatch(getDemoOptionsConfigAction());
    };

    useEffect(initializeSettingsConfig, []);

    /**
     * Function that initializes the amplify config for this page/file;
     * since this page is public, it will use API key as authenticator.
     */
    const setupAmplifyConfig = () => {
        if (!isEmpty(regionSettingsConfig) && !isEmpty(regionKeyConfig)) {
            const { search } = props.location;
            const urlQueries = QueryString.parse(search);
            const usedRegion =
                toString(urlQueries[notificationsQueryString.REGION]) ||
                DEFAULT_REGION_NAME;

            const config = getRegionConfigFromList(
                usedRegion,
                regionKeyConfig,
                regionSettingsConfig
            );

            const amplifyPublicConfig = generateAmplifyPublicConfig(config);
            Amplify.configure(amplifyPublicConfig);
            setUsedAmplifyConfig(amplifyPublicConfig);
        }
    };

    useEffect(setupAmplifyConfig, [regionKeyConfig, regionSettingsConfig]);

    const companyPrimaryColorTheme = get(
        paymentScheduleDetails,
        'Company.PrimaryColorTheme'
    );

    const languagePack = get(paymentScheduleDetails, 'Company.LanguagePackage');

    const initializeCompanyTheme = () => {
        primaryColorTheme = companyPrimaryColorTheme || initialPrimaryColor;
        contrastPrimaryColor = getContrastColor(primaryColorTheme);
    };

    useEffect(initializeCompanyTheme, [companyPrimaryColorTheme]);

    const setLocale = () => {
        if (isUndefined(languagePack)) return;

        const companyLocale = get(languagePack, 'Language');
        if (companyLocale) {
            dispatch(setLocaleAction(companyLocale));
        }
    };

    useEffect(setLocale, [languagePack]);

    /**
     * Function called to add a query param to url which will then trigger the `Inform Us` panel.
     */
    const openInformUsPanel = () => {
        const { pathname, search } = props.location;
        const urlQueries = QueryString.parse(search);
        urlQueries.o = 'inf';
        const newUrlQueries = QueryString.stringify(urlQueries);
        const urlToGo = isEmpty(urlQueries)
            ? pathname
            : `${pathname}?${newUrlQueries}`;
        props.history.push(urlToGo);
    };

    /**
     * Common function for updating the ezidebitstate
     * @param eziDebitStateObject
     */
    const updateEziDebitStateObject = (eziDebitStateObject: DynamicObject) => {
        setEziDebitState({
            ...eziDebitState,
            ...eziDebitStateObject,
        });
    };

    /**
     * Common function for updating the advamstate
     * @param advamStateObject
     */
    const updateADVAMStateObject = (advamStateObject: DynamicObject) => {
        setAdvamState({
            ...advamState,
            ...advamStateObject,
        });
    };

    /**
     * Common function for updating the attachmentsState state.
     * @param attachmentsStateObject
     */
    const updateAttachmentsStateObject = (
        attachmentsStateObject: DynamicObject
    ) => {
        setAttachmentsState({
            ...attachmentsState,
            ...attachmentsStateObject,
        });
    };

    /**
     * Function called for fetching the details needed to populate components in this page.
     * @param withDelay - boolean indicator if we are to apply 3 seconds delay before API is called
     */
    const fetchPaymentScheduleDetails = (withDelay = false) => {
        if (!usedAmplifyConfig) {
            return;
        }

        setPaymentScheduleDetails({
            ...paymentScheduleDetails,
            loading: true,
        });

        const notificationId = props.match.params.notificationId;
        let applyDelay = withDelay;

        if (applyDelay === false) {
            applyDelay =
                Cookies.get(COOKIE_NOTIFICATION_DELAY_FETCH) === 'true';
            Cookies.remove(COOKIE_NOTIFICATION_DELAY_FETCH, { path: '/' });
        }

        dispatch(
            fetchPaymentPlanCommunicationDetail(
                notificationId,
                applyDelay,
                (response: any) => {
                    const paymentScheduleDetailsResponse = get(
                        response,
                        'data.GetPaymentPlanCommunicationDetail'
                    );

                    let hasError = !response.IsSuccess;
                    let errorMessages = response.Messages;
                    if (
                        isEmpty(get(paymentScheduleDetailsResponse, 'Company'))
                    ) {
                        hasError = true;
                        errorMessages = ['Payment schedule details not found.'];
                    }

                    if (!hasError) initialLoadingDone = true;
                    setPaymentScheduleDetails({
                        ...paymentScheduleDetailsResponse,
                        hasError,
                        loading: false,
                        errorMessages,
                    });
                    document.body.style.overflowY = 'auto';
                }
            )
        );
    };

    useEffect(fetchPaymentScheduleDetails, [
        props.match.params.notificationId,
        usedAmplifyConfig,
    ]);

    /**
     * Function called when closing the `Inform Us` panel.
     * @param refetchDetails - boolean indicator if data in this page should be refetched due to some updates.
     */
    const closeExtraQueryStringPanel = async (refetchDetails: boolean) => {
        const { pathname, search } = props.location;
        const urlQueries = QueryString.parse(search);
        if (urlQueries[notificationsQueryString.OPEN])
            delete urlQueries[notificationsQueryString.OPEN];
        if (urlQueries[notificationsQueryString.CACHE_KEY])
            delete urlQueries[notificationsQueryString.CACHE_KEY];
        if (urlQueries[notificationsQueryString.PAY_NOW])
            delete urlQueries[notificationsQueryString.PAY_NOW];
        const newUrlQueries = QueryString.stringify(urlQueries);
        const urlToGo = isEmpty(urlQueries)
            ? pathname
            : `${pathname}?${newUrlQueries}`;
        await props.history.push(urlToGo);

        if (refetchDetails) {
            setTimeout(() => {
                fetchPaymentScheduleDetails(true);
            }, 100);
        }
    };

    const isLoading = paymentScheduleDetails.loading;
    const notificationDetailsError = !isLoading
        ? paymentScheduleDetails.hasError ||
        isEmpty(get(paymentScheduleDetails, 'Company'))
        : false;

    /**
     * Function that gets the invoices by CacheKey from localStorage.
     * @param cacheKey
     */
    const fetchInvoicesByCacheKey = (cacheKey: string) => {
        const usedCacheKey = `${storedInvoicesPrefix}${cacheKey}`;
        const storedInvoices = clone(localStorage.getItem(usedCacheKey));
        if (storedInvoices) {
            const invoiceIdsSelected: string[] = [];
            const parsedStoredInvoiceIds = JSON.parse(storedInvoices);
            forEach(parsedStoredInvoiceIds, (invId: string) => {
                const invoiceId = invId.replace('inv:', '');
                invoiceIdsSelected.push(invoiceId);
            });
            setTotalAmountAndSelectedRowKeys(invoiceIdsSelected);
            if (isEmailPayNowClicked && !isEmailWesternUnionClicked)
                setShowPaymentOptionsPanel(true);
            updateEziDebitStateObject({
                showPanel: true,
                isConfirmation: true,
            });
        }
    };
    /**
    * Function for initializing the corpay currency options based on companyId
    * @param workflowId
    */
    const getCorpayCurrenciesForCompany = (companyId: any) => {
        dispatch(
            getCorpayCurrenciesForCompanyRequestAction({
                companyId,
                callback: (res: any[]) => {
                    setCorpayCurrencies(res)
                },
            })
        );
    };
    /**
     * Listener function responsible for showing/hiding the `Inform Us` panel based on the route.
     */
    const listenForQueryStringPanel = () => {
        if (initialLoadingDone && !isLoading) {
            const { search } = props.location;
            const urlQueries = QueryString.parse(search);
            const oQueryString = urlQueries[notificationsQueryString.OPEN];

            if (informUsPanelRef.current) {
                setTimeout(() => {
                    const isInformUsPanelOpen = oQueryString === 'inf';
                    setInformUsVisible(isInformUsPanelOpen);
                }, 100);
            }

            if (oQueryString === 'ConfirmBankTransfer') {
                const cacheKey = urlQueries[notificationsQueryString.CACHE_KEY];
                if (cacheKey) {
                    fetchInvoicesByCacheKey(cacheKey as string);
                }
            }
            if (corpayPaymentPanelRef.current) {
                const { search } = props.location;
                const urlQueries = QueryString.parse(search);
                const oQueryString = urlQueries[notificationsQueryString.PAY_NOW];

                if (oQueryString == 'cp') {
                    isCorpayClicked = true;
                }
                setTimeout(() => {

                    if (
                        !(paymentScheduleTotal > 0) || get(
                            paymentScheduleDetails,
                            'Company.PaymentOptions.CorpayPaymentOption.IsEnabled'
                        ) !== true
                    )
                        return;

                    const isCorpayPaymentPanelOpen = oQueryString === 'cp';

                    setCorpayPaymentState({
                        ...corpayPaymentState,
                        showCorpayPaymentSelectAutoPanel:
                            isCorpayPaymentPanelOpen,
                    });

                }, 100);

                getCorpayCurrenciesForCompany(get(paymentScheduleDetails.Company, 'CompanyId'));
            }
            if (urlQueries[notificationsQueryString.PAY_NOW] === 'c') {
                if (
                    cardPaymentSystemSelected &&
                    overseasCardPaymentSystemSelected
                ) {
                    isEmailPayNowClicked = true;
                } else if (
                    cardPaymentSystemSelected ===
                    cardPaymentSystems.PAYMENT_URL ||
                    cardPaymentSystemSelected ===
                    cardPaymentSystems.INTEGRAPAY ||
                    cardPaymentSystemSelected === cardPaymentSystems.EZIDEBIT ||
                    cardPaymentSystemSelected === cardPaymentSystems.ADVAM ||
                    overseasCardPaymentSystemSelected ===
                    cardPaymentSystems.PAYMENT_URL
                ) {
                    isEmailPayNowClicked = true;
                }
            } else if (urlQueries[notificationsQueryString.PAY_NOW] === 'w') {
                isEmailWesternUnionClicked = true;
            }
        }
    };

    useEffect(listenForQueryStringPanel, [props.location.search, isLoading, corpayPaymentPanelRef]);

    const listenForInstructCorpayPaymentDetails = () => {
        if (!isEmpty(corpayInstructPaymentDetails.OrderId)) {

            sendCorpayPayment(paymentScheduleTotal, undefined, corpayInstructPaymentDetails)
            
        }
    };

    useEffect(listenForInstructCorpayPaymentDetails, [corpayInstructPaymentDetails]);
    /**
     * Common function for formatting an amount into currency value.
     * @param amount - number to format
     */
    const handleFormatCurrency = (amount: number) => {
        return props.formatCurrency ? props.formatCurrency(amount) : null;
    };

    /**
     * Function that sets the Invoice total amount and responsible for setting the selected/unselected row keys.
     * @param selectedRowKeys
     */
    const setTotalAmountAndSelectedRowKeys = (
        selectedRowKeys: any[],
        initialLoad: boolean = false
    ) => {
        const totalPayment = paymentScheduleTotal;

        if (
            initialLoad &&
            (isEmailPayNowClicked || isEmailWesternUnionClicked) &&
            totalPayment > 0
        ) {
            if (isEmailWesternUnionClicked) {
                navigateToWesternUnionPaymentUrl(totalPayment);
            } else {
                processActionBasedOnPaymentGateway(totalPayment);
            }
        }

        setTableSelection({
            totalPayment,
            selectedRowKeys,
        });
    };

    const onEziDebitPaymentOptionSetAction = (
        totalPayment: number,
        payByCard?: boolean
    ) => {
        const eziDebitEDDRUrlExists = get(
            paymentOptionsData,
            paymentOptionsDataMapping.CardPaymentEziDebitEDDRURLExist
        );
        const isBankTransferConfirmed =
            eziDebitState.isConfirmation && payByCard === false ? true : false;

        if (!isUndefined(payByCard)) {
            addEziDebitPaymentRecord(
                totalPayment,
                payByCard,
                isBankTransferConfirmed
            );
        } else {
            if (eziDebitEDDRUrlExists) {
                updateEziDebitStateObject({
                    showPanel: true,
                });
            } else {
                addEziDebitPaymentRecord(
                    totalPayment,
                    payByCard,
                    isBankTransferConfirmed
                );
            }
        }
    };

    /**
     * Function for adding the cookie to indicate if the next fetch will be delayed or not.
     */
    const addCookieSettingForDelayFetch = () => {
        Cookies.set(COOKIE_NOTIFICATION_DELAY_FETCH, 'true', {
            path: '/',
            expires: addDaysToDate(new Date(), TOKEN_NAME_EXPIRY),
        });
    };

    /**
     * Common function for processing the action to be done for payment.
     * @param totalPayment
     */
    const processActionBasedOnPaymentGateway = (totalPayment: number) => {
        if (cardPaymentSystemSelected && overseasCardPaymentSystemSelected) {
            setShowPaymentOptionsPanel(true);
        } else if (cardPaymentSystemSelected) {
            if (cardPaymentSystemSelected === cardPaymentSystems.PAYMENT_URL) {
                navigateToPaymentUrl(totalPayment, undefined, false);
            } else if (
                cardPaymentSystemSelected === cardPaymentSystems.EZIDEBIT
            ) {
                onEziDebitPaymentOptionSetAction(totalPayment);
            } else if (
                cardPaymentSystemSelected === cardPaymentSystems.INTEGRAPAY
            ) {
                navigateToIntegraPayPaymentUrl(totalPayment);
            } else if (cardPaymentSystemSelected === cardPaymentSystems.ADVAM) {
                addADVAMPaymentRecord(totalPayment);
            }
        } else if (overseasCardPaymentSystemSelected) {
            if (
                overseasCardPaymentSystemSelected ===
                cardPaymentSystems.PAYMENT_URL
            ) {
                navigateToPaymentUrl(totalPayment, undefined, true);
            }
        }
    };

    /**
     * Function for getting invoices for row selection
     */
    const getInvoicesDataForRowSelection = () => {
        // return paymentScheduleDetails.Invoices.filter((i) => {
        //     const hasBlockingTickets: any[] = [];
        //     forEach(i.Tickets, (tic: DynamicObject) => {
        //         const isNonBlocking = get(tic, 'TicketOption.NonBlocking');
        //         if (isNonBlocking !== true && !isEmpty(tic)) {
        //             hasBlockingTickets.push(tic);
        //         }
        //     });

        //     return isEmpty(hasBlockingTickets);
        // });
        return paymentScheduleDetails.Invoices;
    };

    /**
     * Function called on page load and changes to invoices.
     * This will initialize the selected row keys and total payment.
     */
    const initializeSelectedRowKeysAndTotalPayment = () => {
        if (!isEmpty(paymentScheduleDetails.Invoices)) {
            const invoicesWithEmptyTickets: any =
                getInvoicesDataForRowSelection();
            const selectedRowKeys = invoicesWithEmptyTickets.map(
                (i: DynamicObject) => i.Id
            );

            const { search } = props.location;
            const urlQueries = QueryString.parse(search);
            if (
                !urlQueries[notificationsQueryString.OPEN] ||
                urlQueries[notificationsQueryString.OPEN] !==
                'ConfirmBankTransfer'
            ) {
                setTotalAmountAndSelectedRowKeys(selectedRowKeys, true);
            }

            if (
                (isEmailPayNowClicked || isEmailWesternUnionClicked) &&
                !isEmpty(selectedRowKeys)
            ) {
                const totalPayment = paymentScheduleTotal;
                const eziDebitEDDRUrlExists = get(
                    paymentOptionsData,
                    paymentOptionsDataMapping.CardPaymentEziDebitEDDRURLExist
                );
                if (
                    cardPaymentSystemSelected &&
                    overseasCardPaymentSystemSelected &&
                    !isEmailWesternUnionClicked &&
                    totalPayment > 0
                ) {
                    setShowPaymentOptionsPanel(true);
                } else if (
                    cardPaymentSystemSelected === cardPaymentSystems.EZIDEBIT &&
                    eziDebitEDDRUrlExists === true &&
                    totalPayment > 0 &&
                    !isEmailWesternUnionClicked
                ) {
                    processActionBasedOnPaymentGateway(totalPayment);
                } else {
                    routePaymentActionsHandler(
                        selectedRowKeys,
                        undefined,
                        undefined,
                        totalPayment
                    );
                }
            }
        }
    };

    useEffect(initializeSelectedRowKeysAndTotalPayment, [
        paymentScheduleDetails.Invoices,
    ]);

    // Will unmount
    useEffect(() => {
        return () => {
            initialLoadingDone = false;
        };
    }, []);

    const customFieldsByNumberSorted = orderBy(
        get(paymentScheduleDetails, 'Company.CustomFieldsConfigure'),
        ['Number'],
        ['asc']
    );

    /**
     * Custom fields for invoice table
     */
    const invoiceInNotificationsCustomFields: {
        title: string;
        dataIndex: string;
    }[] = [];
    forEach(
        customFieldsByNumberSorted,
        ({
            DisplayInNotification,
            Type,
            FieldName,
        }: CompanyCustomFieldConfigure) => {
            if (
                Type === CUSTOM_FIELD_TYPES.INVOICE &&
                DisplayInNotification === true
            ) {
                invoiceInNotificationsCustomFields.push({
                    title: FieldName,
                    dataIndex: lowerCase(
                        `${customFieldIndicator}${Type}--${FieldName}`
                    ),
                });
            }
        }
    );

    const customFieldsDataIndex = map(
        invoiceInNotificationsCustomFields,
        'dataIndex'
    );

    const openAttachmentsPanel = (record: DynamicObject) => {
        updateAttachmentsStateObject({
            showPanel: true,
            selectedRecordForAttachment: record,
        });
    };

    const closeAttachmentsPanel = () => {
        updateAttachmentsStateObject({
            showPanel: false,
            selectedRecordForAttachment: null,
        });
    };

    /**
     * Function that gets the custom fields for invoice table object.
     * @param data - invoice data
     */
    const getInvoiceCustomFieldsTableObject = (data: any) => {
        const invoiceCustomFields = get(data, 'CustomFields');
        const invoiceCustomFieldsTableObject: DynamicObject = {};

        forEach(invoiceCustomFields, ({ Name, Value }: CustomField) => {
            const invoiceCustomFieldDataIndexName = lowerCase(
                `${customFieldIndicator}${CUSTOM_FIELD_TYPES.INVOICE}--${Name}`
            );

            const isNameInIndexCaseInsensitive = some(
                customFieldsDataIndex,
                (invoiceDataIdxName: string) => {
                    return (
                        invoiceDataIdxName === invoiceCustomFieldDataIndexName
                    );
                }
            );

            if (isNameInIndexCaseInsensitive) {
                invoiceCustomFieldsTableObject[
                    invoiceCustomFieldDataIndexName
                ] = Value;
            }
        });
        return invoiceCustomFieldsTableObject;
    };

    /**
     * Function that displays the attachment / attachments section after clicking the icon.
     * @param record
     */
    const handleViewAttachments = (record: DynamicObject) => {
        const attachments = get(record, 'Attachments', []);
        if (!isEmpty(attachments)) {
            if (attachments.length === 1) {
                handleViewFile(false, get(attachments, 0));
            } else {
                openAttachmentsPanel(record);
            }
        }
    };

    /**
     * Common function for populating the attachment icon on invoice
     * @param data
     */
    const populateAttachmentsTableData = (data: DynamicObject) => {
        return !isEmpty(data.Attachments) ? (
            <Tooltip
                title={`${data.Attachments.length} attachment${data.Attachments.length > 1 ? 's' : ''
                    } available`}
                placement="topRight"
            >
                <Button
                    className="pa-0"
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleViewAttachments(data);
                    }}
                >
                    <FontAwesome
                        icon={['fas', 'file-alt']}
                        style={{
                            fontSize: 20,
                        }}
                    />
                </Button>
            </Tooltip>
        ) : null;
    };
    
    const populateInformUsLabel = () => get(customization, 'InformUsTextLabel') || defaultDisplay.InformUsTextLabel;

    const populateInformUsInstruction = () => {
        let instruction = get(customization, 'InformUsInstruction') || defaultDisplay.InformUsInstruction;

        if (instruction && instruction.includes(customizationPlaceholders.INFORM_US_LABEL)) {
            instruction = instruction.replace(customizationPlaceholders.INFORM_US_LABEL, populateInformUsLabel() || '');
        }

        return instruction;
    };

    const demoOptionsConfig = useSelector(getDemoOptionsConfig);

    const isPaymentPlanEnabled =
        includes(
            get(demoOptionsConfig, 'PAYMENT_PLAN'),
            get(paymentScheduleDetails.Company, 'CompanyId')
        ) || get(paymentScheduleDetails.Company, 'CompanyPaymentPlan.IsEnabled');

    /**
     * Function that adds payment plan info to extradata list for carousel
     * @param extraData
     * @param payment
     */
    const addPaymentPlanToExtraData = (extraData: any[], paymentPlan: any) => {
        const isPending = get(paymentPlan, 'State') === 'Pending';
        const displayText = isPending
            ? 'Payment plan requested'
            : 'Payments via a payment plan';
        extraData.push(<div key="payment-plan-info">{displayText}</div>);

        return extraData;
    };

    /**
     * Function that adds ticket item to extradata list for carousel
     * @param extraData
     * @param ticket
     */
    const addTicketToExtraData = (extraData: any[], ticket: any) => {
        extraData.push(
            <div key={`ticket-${get(ticket, 'TicketNumber')}`}>
                Open ticket #{get(ticket, 'TicketNumber')} -{' '}
                {get(ticket, 'TicketOption.Reason')}
            </div>
        );

        return extraData;
    };

    /**
     * Function responsible for mapping the invoice information.
     * @param invoice
     */
    const invoiceInformation = (invoice: any) => {
        let extraData: any[] = [];
        
        const paymentPlanInfo = invoice.PaymentPlan;
        const hasPaymentPlan =
            isPaymentPlanEnabled && !isEmpty(paymentPlanInfo);

        if (hasPaymentPlan) {
            const paymentPlanState = get(paymentPlanInfo, 'State');
            const isValidPaymentPlan = paymentPlanState !== 'Cancelled' && paymentPlanState !== 'Rejected';
            if (isValidPaymentPlan) {
                extraData = addPaymentPlanToExtraData(extraData, paymentPlanInfo);
            }
        }

        if (!isEmpty(invoice.Tickets)) {
            let ticketsLength = hasPaymentPlan ? 4 : 5;
            //if (!isEmpty(paymentsInfo)) ticketsLength--;

            const ticketsSorted = orderBy(
                invoice.Tickets,
                (tiObj: DynamicObject) => {
                    const ticketNumber = get(tiObj, 'TicketNumber');
                    return ticketNumber ? parseInt(ticketNumber) : undefined;
                },
                ['desc']
            );

            forEach(ticketsSorted, (ticket: any) => {
                if (extraData.length <= ticketsLength && !isEmpty(ticket)) {
                    extraData = addTicketToExtraData(extraData, ticket);
                }
            });
        }

        if (isEmpty(extraData)) return null;

        let carouselRef: any = null;
        const showButton = extraData.length > 1;
        const onPrevAction = () => {
            if (carouselRef) carouselRef.prev();
        };
        const onNextAction = () => {
            if (carouselRef) carouselRef.next();
        };
        return (
            <div id="carousel-container">
                {showButton && (
                    <Button
                        className="l-icon"
                        icon="left"
                        size="small"
                        shape="circle"
                        type="link"
                        onClick={onPrevAction}
                        onTouchStart={onPrevAction}
                    />
                )}

                <Carousel
                    ref={(ref) => (carouselRef = ref)}
                    className="custom-carousel"
                    autoplay={true}
                    autoplaySpeed={15000}
                    pauseOnFocus
                >
                    {extraData}
                </Carousel>
                {showButton && (
                    <Button
                        className="r-icon"
                        icon="right"
                        size="small"
                        shape="circle"
                        type="link"
                        onClick={onNextAction}
                        onTouchStart={onNextAction}
                    />
                )}
            </div>
        );
    };

    /**
     * Data source needed by Ant design table to map the values from api to each specific columns and rows.
     */
    const dataSource = map(paymentScheduleDetails.Invoices, (data: any) => {
        const invoiceCustomFieldsTableObject =
            getInvoiceCustomFieldsTableObject(data);

        return {
            ...data,
            key: data.Id,
            AmountOwing: handleFormatCurrency(data.AmountOwing),
            DueDate: props.formatDateLocal(data.LocalDueDate),
            Attachments: populateAttachmentsTableData(data),
            Information: invoiceInformation(data),
            ...invoiceCustomFieldsTableObject,
        };
    });

    let companyLogo = get(
        paymentScheduleDetails,
        'Company.LogoPath',
        `${assetsPath}/imagePlaceholder.png`
    );
    if (companyLogo) {
        companyLogo = invalidateImagePathCache(companyLogo);
    }

    const columns: any = [
        {
            title: 'Invoice no.',
            dataIndex: 'Number',
        },
        {
            title: 'Amount owing',
            dataIndex: 'AmountOwing',
        },
        {
            title: 'Due date',
            dataIndex: 'DueDate',
        },
        {
            title: 'View attachments',
            dataIndex: 'Attachments',
            align: 'center',
        },
        ...invoiceInNotificationsCustomFields,
        {
            title: 'Information',
            dataIndex: 'Information',
        },
    ];

    const iconStyle = {
        color: initialPrimaryColor,
    };

    /**
     * Function for populating error messages when fetching of details failed.
     */
    const populateErrorMessages = () => {
        const errorMessagesComponent = map(
            paymentScheduleDetails.errorMessages,
            (errorMessage: string, index: number) => (
                <span className="d-block grey" key={index}>
                    {errorMessage}
                </span>
            )
        );

        return (
            <>
                <span className="d-block">{errorMessagesComponent}</span>
                <span className="d-block">
                    <Button
                        className="pa-0"
                        type="link"
                        onClick={() => props.history.push('/')}
                    >
                        Go to the Main Page
                    </Button>
                </span>
            </>
        );
    };

    /**
     * Common function for updating preparingFileLink state.
     * @param preparingFileLinkObject
     */
    const updatePreparingFileLinkObject = (
        preparingFileLinkObject: DynamicObject
    ) => {
        setPreparingFileLink({
            ...preparingFileLink,
            ...preparingFileLinkObject,
        });
    };

    /**
     * Function for viewing the letter that contains the outline for payment request or attachment.
     * @param isLetter
     * @param attachment
     */
    const handleViewFile = (isLetter = false, attachment?: DynamicObject) => {
        updatePreparingFileLinkObject({
            loading: true,
            isLetter,
        });

        const fileId: any = get(attachment, 'FileId');

        if (fileId) {
            dispatch(
                getNotificationLetterPresignedUrlAction(
                    fileId,
                    (response: any) => {
                        if (response.IsSuccess) {
                            const fileURL = get(
                                response,
                                'data.GetFileLocation'
                            );
                            if (fileURL && fileURL !== 'null') {
                                openURLNewTab(fileURL);
                            } else {
                                Modal.error({
                                    title: 'Error generating link',
                                    content: 'File Id might be invalid',
                                });
                            }
                        }
                        updatePreparingFileLinkObject({
                            loading: false,
                        });
                    }
                )
            );
        } else {
            updatePreparingFileLinkObject({
                loading: false,
            });
            const isExternal = get(attachment, 'IsExternal');
            const externalUrl = get(attachment, 'ExternalUrl');
            if (isExternal && externalUrl) openURLNewTab(externalUrl);
        }
    };

    /**
     * Function that populates the custom fields on invoices table for mobile view
     */
    const populateCustomFieldsSection = (data: any) => {
        const invoiceCustomFieldsTableObject =
            getInvoiceCustomFieldsTableObject(data);
        return map(
            invoiceInNotificationsCustomFields,
            ({ title }: { title: string; dataIndex: string }) => {
                const invoiceCustomFieldDataIndexName = lowerCase(
                    `${customFieldIndicator}${CUSTOM_FIELD_TYPES.INVOICE}--${title}`
                );
                const value =
                    invoiceCustomFieldsTableObject[
                    invoiceCustomFieldDataIndexName
                    ];

                return (
                    <Row key={invoiceCustomFieldDataIndexName}>
                        <Col span={11}>{title}</Col>
                        <Col span={13}>{value}</Col>
                    </Row>
                );
            }
        );
    };

    /**
     * Function that populates the panel section if viewed on small screen devices.
     */
    const generatePanelSection = () => {
        if (notificationDetailsError) {
            return <div>{populateErrorEmptyComponent()}</div>;
        } else {
            const panelItems = map(
                dataSource,
                ({ Id, ...data }: { Id: string; data: any }) => {
                    return (
                        <Panel
                            key={Id}
                            header={
                                <Row type="flex" align="middle">
                                    <Col span={12}>Invoice No &emsp; </Col>
                                    <Col span={10}>{get(data, 'Number')}</Col>
                                    <Col span={2}>
                                        {get(data, 'Attachments')}
                                    </Col>
                                </Row>
                            }
                            className="panel-item-padding-adjusted"
                        >
                            <div className="pb-10">
                                <Row>
                                    <Col span={11}>Amount Owing</Col>
                                    <Col span={13}>
                                        {get(data, 'AmountOwing')}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>Due Date</Col>
                                    <Col span={13}>{get(data, 'DueDate')}</Col>
                                </Row>
                                {populateCustomFieldsSection(data)}
                                <Row>
                                    <Col span={11}>Information</Col>
                                    <Col span={13}>
                                        {get(data, 'Information')}
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    );
                }
            );
            return (
                <Collapse
                    className="themed-collapse"
                    expandIconPosition="right"
                >
                    {panelItems}
                </Collapse>
            );
        }
    };

    /**
     * Function for getting the complete post address based on the Company object from API.
     */
    const getCompletePostAddress = () => {
        const suburb = get(paymentScheduleDetails, 'Company.Suburb');
        const state = get(paymentScheduleDetails, 'Company.State');
        const postcode = get(paymentScheduleDetails, 'Company.Postcode');

        let completePostAddress = '';
        if (suburb) completePostAddress += suburb;
        if (!isEmpty(completePostAddress)) completePostAddress += ', ';
        if (state) completePostAddress += state;
        if (!isEmpty(completePostAddress)) completePostAddress += ', ';
        if (postcode) completePostAddress += postcode;

        return completePostAddress;
    };

    const cardPaymentSystemSelected = get(
        paymentOptionsData,
        paymentOptionsDataMapping.CardPaymentSystem
    );

    const overseasCardPaymentSystemSelected = get(
        paymentOptionsData,
        paymentOptionsDataMapping.OverseasCardPaymentSystem
    );

    const isMailingChequePaymentEnabled = get(
        paymentOptionsData,
        paymentOptionsDataMapping.MailingChequePaymentEnabled
    );

    const isBankPaymentEnabled = get(
        paymentOptionsData,
        paymentOptionsDataMapping.BankPaymentOptionEnabled
    );

    const isWesternUnionPaymentEnabled = get(
        paymentOptionsData,
        paymentOptionsDataMapping.WesternUnionPaymentOptionEnabled
    );

    const isCorpayPaymentEnabled = get(
        paymentOptionsData,
        paymentOptionsDataMapping.CorpayPaymentOptionEnabled
    );

    const corpayPaymentOptionStatus = get(
        paymentOptionsData,
        paymentOptionsDataMapping.CorpayPaymentOptionStatus
    );
    const address1 = get(paymentScheduleDetails, 'Company.AddressLine1');
    const address2 = get(paymentScheduleDetails, 'Company.AddressLine2');

    const completePostAddress = getCompletePostAddress();

    /**
     * Function that handles clicking on `Pay Now` button in Card Payment widget.
     */
    const onPayNowClick = () => {
        isEmailWesternUnionClicked = false;
        if (cardPaymentSystemSelected && overseasCardPaymentSystemSelected) {
            setShowPaymentOptionsPanel(true);
        } else {
            const eziDebitEDDRUrlExists = get(
                paymentOptionsData,
                paymentOptionsDataMapping.CardPaymentEziDebitEDDRURLExist
            );

            if (
                !isEmpty(tableSelection.selectedRowKeys) &&
                paymentScheduleTotal > 0 &&
                (cardPaymentSystemSelected !== cardPaymentSystems.EZIDEBIT ||
                    eziDebitEDDRUrlExists === false)
            ) {
                routePaymentActionsHandler(tableSelection.selectedRowKeys);
            } else {
                processActionBasedOnPaymentGateway(paymentScheduleTotal);
            }
        }
    };

    /**
     * Function called on Western Union pay button click
     */
    const onWUPayNowClick = () => {
        isEmailWesternUnionClicked = true;
        if (isWesternUnionPaymentEnabled) {
            if (
                !isEmpty(tableSelection.selectedRowKeys) &&
                paymentScheduleTotal > 0
            ) {
                routePaymentActionsHandler(tableSelection.selectedRowKeys);
            } else {
                navigateToWesternUnionPaymentUrl(paymentScheduleTotal);
            }
        }
    };

    /**
     * Function that hides the notification payment options panel.
     */
    const closePaymentOptionsPanel = () => {
        setShowPaymentOptionsPanel(false);
    };

    /**
     * Function that hides the notification ezidebit payment options panel.
     */
    const closeEziDebitPaymentOptionsPanel = () => {
        const { search } = props.location;
        const urlQueries = QueryString.parse(search);
        const oQueryString = urlQueries[notificationsQueryString.OPEN];

        updateEziDebitStateObject({
            showPanel: false,
            isConfirmation: false,
        });

        if (oQueryString === 'ConfirmBankTransfer') {
            closeExtraQueryStringPanel(false);
            setShowPaymentOptionsPanel(false);
        }
    };

    /**
     * Function that hides the notification advam payment options panel.
     */
    const closeAdvamPaymentOptionsPanel = () => {
        updateADVAMStateObject({
            showPanel: false,
            iframeURL: undefined,
        });
    };

    /**
     * Common function for getting the totalpayment rounded value as string
     * @param totalPayment
     */
    const getTotalPaymentRounded = (totalPayment: number) => {
        return roundNumberToDecimalDigits(totalPayment);
    };

    /**
     * Common function for getting the invoice ids.
     * @param invoiceIds
     */
    const getInvoiceIds = (invoiceIds: string[]) => {
        return isEmpty(invoiceIds)
            ? map(get(paymentScheduleDetails, 'Invoices'), 'Id')
            : invoiceIds;
    };

    /**
     * Function called when navigation options payment gateway is chosen.
     * @param totalPayment
     * @param cardPaymentSelected
     * @param isOverseas
     */
    const onPaymentGatewayChoose = (
        totalPayment: number,
        cardPaymentSelected: string,
        isOverseas?: boolean
    ) => {
        const eziDebitEDDRUrlExists = get(
            paymentOptionsData,
            paymentOptionsDataMapping.CardPaymentEziDebitEDDRURLExist
        );

        if (
            !isEmpty(tableSelection.selectedRowKeys) &&
            totalPayment > 0 &&
            (cardPaymentSelected !== cardPaymentSystems.EZIDEBIT ||
                eziDebitEDDRUrlExists === false)
        ) {
            routePaymentActionsHandler(
                tableSelection.selectedRowKeys,
                cardPaymentSelected,
                isOverseas
            );
        } else {
            actionAfterPaymentOptionClick(
                totalPayment,
                cardPaymentSelected,
                isOverseas
            );
        }
    };

    /**
     * Function called when payment option on EziDebit panel is chosen.
     * @param totalPayment
     * @param payByCreditCard
     */
    const onEziDebitPaymentOptionChoose = (
        totalPayment: number,
        payByCreditCard: boolean
    ) => {
        const cardPaymentSelected = cardPaymentSystems.EZIDEBIT;
        const isOverseas = false;

        if (!isEmpty(tableSelection.selectedRowKeys) && totalPayment > 0) {
            routePaymentActionsHandler(
                tableSelection.selectedRowKeys,
                cardPaymentSelected,
                isOverseas,
                totalPayment, // check to add totalPayment
                payByCreditCard
            );
        } else {
            actionAfterPaymentOptionClick(
                totalPayment,
                cardPaymentSelected,
                isOverseas,
                payByCreditCard
            );
        }
    };

    /**
     * Action fired after payment option click or initial load with automated pay now action.
     * @param totalPayment
     * @param paymentOptionSelected
     * @param isOverseas
     */
    const actionAfterPaymentOptionClick = (
        totalPayment: number,
        paymentOptionSelected?: string,
        isOverseas?: boolean,
        payByCard?: boolean
    ) => {
        if (paymentOptionSelected === cardPaymentSystems.PAYMENT_URL) {
            navigateToPaymentUrl(totalPayment, undefined, isOverseas);
        } else if (paymentOptionSelected === cardPaymentSystems.EZIDEBIT) {
            onEziDebitPaymentOptionSetAction(totalPayment, payByCard);
        } else if (paymentOptionSelected === cardPaymentSystems.INTEGRAPAY) {
            navigateToIntegraPayPaymentUrl(totalPayment, undefined);
        } else if (paymentOptionSelected === cardPaymentSystems.ADVAM) {
            addADVAMPaymentRecord(totalPayment);
        }
        // Global payment options
        else if (paymentOptionSelected === globalPaymentSystems.WESTERN_UNION) {
            navigateToWesternUnionPaymentUrl(totalPayment, undefined);
        }
    };

    /**
     * Common function for getting customerCode.
     */
    const getCustomerCode = () => {
        return get(paymentScheduleDetails, 'Customer.CustomerCode');
    };

    /**
     * Common function for getting customer display name.
     */
    const getCustomerName = () => {
        return get(paymentScheduleDetails, 'Customer.DisplayName');
    };

    /**
     * Function to get the LegalVision Invoice List data.
     */
    const getLVInvoiceListData = () => {
        let invoiceListString = '';
        let invoiceIds: string[] = [];
        let totalPayment = 0;
        const invoicesWithEmptyTickets: any = getInvoicesDataForRowSelection();
        forEach(invoicesWithEmptyTickets, (data: DynamicObject) => {
            if (invoiceListString !== '') {
                invoiceListString += '&';
            }
            invoiceListString += `id=${get(data, 'Number', '').replace(
                'INV-',
                ''
            )}`;
            invoiceIds.push(data.Id);
            totalPayment += data.AmountOwing;
        });

        return {
            invoiceListString,
            lvInvoiceIds: invoiceIds,
            lvTotalPayment: totalPayment,
        };
    };

    /**
     * Function to get the Invoice List data.
     */
    const getInvoiceNumbersList = (separatorUsed: string) => {
        let invoiceListString = '';
        const invoicesWithEmptyTickets: any = getInvoicesDataForRowSelection();
        forEach(invoicesWithEmptyTickets, (inv) => {
            if (
                isEmailPayNowClicked ||
                isEmailWesternUnionClicked ||
                includes(tableSelection.selectedRowKeys, get(inv, 'Id'))
            ) {
                if (invoiceListString !== '') {
                    invoiceListString += separatorUsed;
                }
                invoiceListString += get(inv, 'Number', '');
            }
        });

        return invoiceListString;
    };

    /**
     * Function that navigates to the poyment url with the correct total amount after payment record has been added..
     * @param totalPayment
     * @param invoiceIds
     * @param isOverseas
     */
    const navigateToPaymentUrl = (
        totalPayment: number,
        invoiceIds = tableSelection.selectedRowKeys,
        isOverseas: boolean = false
    ) => {
        const customerCode = getCustomerCode();
        const companyTimezone = get(paymentScheduleDetails, 'Company.Timezone');
        const companyLocalNow = moment()
            .tz(companyTimezone)
            .format(dateFormatNowPlaceholder);
        const paymentURLWithPlaceholders = get(
            paymentOptionsData,
            paymentOptionsDataMapping[
            `${isOverseas ? 'Overseas' : ''}CardPaymentPaymentURL`
            ]
        );

        let processedPaymentUrl = paymentURLWithPlaceholders;

        if (includes(processedPaymentUrl, legalVisionInvoiceListPlaceholder)) {
            const { invoiceListString, lvInvoiceIds, lvTotalPayment } =
                getLVInvoiceListData();

            if (isEmpty(invoiceListString)) {
                setAddPaymentLoading(false);
                if (showPaymentOptionsPanel) setShowPaymentOptionsPanel(false);
                return;
            }
            processedPaymentUrl = processedPaymentUrl.replace(
                legalVisionInvoiceListPlaceholder,
                invoiceListString
            );

            totalPayment = lvTotalPayment;
            invoiceIds = lvInvoiceIds;
        }

        const totalPaymentRounded = getTotalPaymentRounded(totalPayment);
        const usedInvoiceIds = getInvoiceIds(invoiceIds);
        if (includes(processedPaymentUrl, customerNumberPlaceholder)) {
            processedPaymentUrl = processedPaymentUrl.replace(
                customerNumberPlaceholder,
                customerCode
            );
        }
        if (includes(processedPaymentUrl, paymentTotalPlaceholder)) {
            processedPaymentUrl = replaceAllStrDynamic(
                processedPaymentUrl,
                paymentTotalPlaceholder,
                totalPaymentRounded
            );
        }
        if (includes(processedPaymentUrl, nowPlaceholder)) {
            processedPaymentUrl = replaceAllStrDynamic(
                processedPaymentUrl,
                nowPlaceholder,
                companyLocalNow
            );
        }

        const clientReferenceUsed = getPaymentClientReference(false);
        setAddPaymentLoading(true);
        const payload: AddPaymentForUrlRequestPayload = {
            Result: paymentResults.UNCONFIRMED,
            PaidDate: formatDateToDateObjectUTC(
                moment(),
                undefined,
                true
            ).format(dateFormatYYYYMMDDTHHmmssDash),
            PaidAmount: totalPaymentRounded,
            InvoiceIds: usedInvoiceIds,
            CustomerId: get(paymentScheduleDetails, 'Customer.Id'),
            CompanyId: get(paymentScheduleDetails, 'Company.CompanyId'),
            CommunicationId: props.match.params.notificationId,
            ClientReference: clientReferenceUsed,
        };

        dispatch(
            addPaymentForUrlRequestAction(
                payload,
                (response: DynamicObject) => {
                    if (get(response, 'IsSuccess') === false) {
                        setAddPaymentLoading(false);

                        return Modal.error({
                            title: 'Error',
                            content: get(
                                response,
                                'Messages.0',
                                'Failed to collect payment information!'
                            ),
                        });
                    }

                    window.location.href = processedPaymentUrl;
                }
            )
        );
    };

    /**
     * Function for getting the client reference
     */
    const getPaymentClientReference = (isWesternUnion: boolean = false) => {
        const clientReferenceDbValue = isWesternUnion
            ? get(
                paymentScheduleDetails,
                `Company.PaymentOptions.${paymentOptionsDataMapping.WesternUnionClientReference}`
            )
            : get(
                paymentScheduleDetails,
                `Company.PaymentOptions.${paymentOptionsDataMapping.CardPaymentClientReference}`
            );

        const clientReferenceUsed =
            clientReferenceDbValue || defaultCardPaymentClientRef;

        let clientReferenceForReturn = clientReferenceUsed;

        if (
            includes(clientReferenceForReturn, customerCodePlaceholder) ||
            includes(clientReferenceForReturn, customerNumberPlaceholder)
        ) {
            const customerCode = getCustomerCode();
            if (includes(clientReferenceForReturn, customerCodePlaceholder)) {
                clientReferenceForReturn = replaceAllStrDynamic(
                    clientReferenceForReturn,
                    customerCodePlaceholder,
                    customerCode
                );
            }

            if (includes(clientReferenceForReturn, customerNumberPlaceholder)) {
                clientReferenceForReturn = replaceAllStrDynamic(
                    clientReferenceForReturn,
                    customerNumberPlaceholder,
                    customerCode
                );
            }
        }
        if (includes(clientReferenceForReturn, customerNamePlaceholder)) {
            const customerName = getCustomerName();
            clientReferenceForReturn = replaceAllStrDynamic(
                clientReferenceForReturn,
                customerNamePlaceholder,
                customerName
            );
        }
        if (includes(clientReferenceForReturn, invoiceListPlaceholder)) {
            const invoiceList = getInvoiceNumbersList(',');
            clientReferenceForReturn = replaceAllStrDynamic(
                clientReferenceForReturn,
                invoiceListPlaceholder,
                invoiceList
            );
        }

        return clientReferenceForReturn;
    };

    /**
     * Function that navigates to the western union payment url after payment record has been added.
     * @param totalPayment
     * @param invoiceIds
     */
    const navigateToWesternUnionPaymentUrl = (
        totalPayment: number,
        invoiceIds = tableSelection.selectedRowKeys
    ) => {
        const totalPaymentRounded = getTotalPaymentRounded(totalPayment);
        const usedInvoiceIds = getInvoiceIds(invoiceIds);

        setAddPaymentLoading(true);
        const clientReferenceUsed = getPaymentClientReference(true);

        const payload: AddPaymentForWesternUnionRequestPayload = {
            Result: paymentResults.UNCONFIRMED,
            PaidDate: formatDateToDateObjectUTC(
                moment(),
                undefined,
                true
            ).format(dateFormatYYYYMMDDTHHmmssDash),
            PaidAmount: totalPaymentRounded,
            InvoiceIds: usedInvoiceIds,
            CustomerId: get(paymentScheduleDetails, 'Customer.Id'),
            CompanyId: get(paymentScheduleDetails, 'Company.CompanyId'),
            CommunicationId: props.match.params.notificationId,
            ClientReference: clientReferenceUsed,
        };

        addCookieSettingForDelayFetch();

        dispatch(
            addPaymentForWesternUnionRequestAction(
                payload,
                (response: DynamicObject) => {
                    if (get(response, 'IsSuccess') === false) {
                        setAddPaymentLoading(false);

                        return Modal.error({
                            title: 'Error',
                            content: get(
                                response,
                                'Messages.0',
                                'Failed to collect payment information!'
                            ),
                        });
                    }

                    const westernUnionPaymentUrl = get(response, 'RedirectUrl');

                    if (get(response, 'Redirect') && westernUnionPaymentUrl)
                        window.location.href = westernUnionPaymentUrl;
                }
            )
        );
    };
    /**
         * Function that sends the Corpay payment.
         * @param totalPayment
         * @param invoiceIds
         */
    const sendCorpayPayment = (
        totalPayment: number,
        invoiceIds = tableSelection.selectedRowKeys,
        corpayPaymentDetails = corpayInstructPaymentDetails,
        payByCard?: boolean
    ) => {
        const totalPaymentRounded = getTotalPaymentRounded(corpayPaymentDetails.AmountToBeSettled);
        const usedInvoiceIds = getInvoiceIds(invoiceIds);

        setAddPaymentLoading(true);

        let cacheKey: string | undefined = undefined;

        const clientReferenceUsed = getPaymentClientReference(true);

        const payload: AddPaymentForCorpayRequestPayload = {
            Result: paymentResults.UNCONFIRMED,
            PaidDate: formatDateToDateObjectUTC(
                moment(),
                undefined,
                true
            ).format(dateFormatYYYYMMDDTHHmmssDash),
            PaidAmount: totalPayment.toString(),
            CustomerPaidAmount: totalPaymentRounded,
            InvoiceIds: usedInvoiceIds,
            CustomerId: get(paymentScheduleDetails, 'Customer.Id'),
            CompanyId: get(paymentScheduleDetails, 'Company.CompanyId'),
            ClientReference: clientReferenceUsed,
            CommunicationId: props.match.params.notificationId,
            CacheKey: cacheKey,
            OrderId: corpayPaymentDetails.OrderId,
            ClientAccessToken: corpayPaymentDetails.ClientAccessToken,
            SelectedCurrency: corpayPaymentDetails.SelectedCurrency
        };

        addCookieSettingForDelayFetch();

        dispatch(
            addPaymentForCorpayRequestAction(
                payload,
                (response: DynamicObject) => {
                    if (get(response, 'IsSuccess') === false) {
                        setAddPaymentLoading(false);

                        return Modal.error({
                            title: 'Error',
                            content: get(
                                response,
                                'Messages.0',
                                'Failed to collect payment information!'
                            ),
                        })
                    } else if (get(response, 'IsSuccess') === true) {

                        closeExtraQueryStringPanel(true);

                        setCorpaySendInstructionState({
                            ...corpaySendInstructionState,
                            paymentId: get(response, 'PaymentId'),
                        });

                        setAddPaymentLoading(false);

                        openCorpaySendInstructionPanel(get(response, 'PaymentId'));
                    }
                }
            )
        );
    };
    /**
     * Function that navigates to the EziDebit payment url after payment record has been added.
     * @param totalPayment
     * @param invoiceIds
     */
    const addEziDebitPaymentRecord = (
        totalPayment: number,
        payByCard: boolean = true,
        bankTransferConfirmed: boolean = false,
        invoiceIds = tableSelection.selectedRowKeys
    ) => {
        const totalPaymentRounded = getTotalPaymentRounded(totalPayment);
        const usedInvoiceIds = getInvoiceIds(invoiceIds);

        setAddPaymentLoading(true);
        const paymentType = payByCard
            ? eziDebitPaymentTypes.CREDIT_CARD
            : eziDebitPaymentTypes.BANK_ACCOUNT;

        const { search } = props.location;
        const urlQueries = QueryString.parse(search);
        const oQueryString = urlQueries[notificationsQueryString.OPEN];

        let cacheKey: string | undefined = undefined;

        if (
            oQueryString !== 'ConfirmBankTransfer' &&
            paymentType === eziDebitPaymentTypes.BANK_ACCOUNT
        ) {
            cacheKey = uuidv4();
            const cacheKeyUsed = `${storedInvoicesPrefix}${cacheKey}`;
            localStorage.setItem(cacheKeyUsed, JSON.stringify(invoiceIds));
        }

        const clientReferenceUsed = getPaymentClientReference();

        const payload: AddPaymentForEziDebitRequestPayload = {
            CacheKey: cacheKey,
            Result: paymentResults.UNCONFIRMED,
            PaidDate: formatDateToDateObjectUTC(
                moment(),
                undefined,
                true
            ).format(dateFormatYYYYMMDDTHHmmssDash),
            PaidAmount: totalPaymentRounded,
            InvoiceIds: usedInvoiceIds,
            CustomerId: get(paymentScheduleDetails, 'Customer.Id'),
            CompanyId: get(paymentScheduleDetails, 'Company.CompanyId'),
            CommunicationId: props.match.params.notificationId,
            ClientReference: clientReferenceUsed,
            PaymentType: paymentType,
            BankTransferConfirmed: bankTransferConfirmed,
        };

        addCookieSettingForDelayFetch();

        dispatch(
            addPaymentForEziDebitRequestAction(
                payload,
                (response: DynamicObject) => {
                    if (get(response, 'IsSuccess') === false) {
                        setAddPaymentLoading(false);

                        return Modal.error({
                            title: 'Error',
                            content: get(
                                response,
                                'Messages.0',
                                'Failed to collect payment information!'
                            ),
                        });
                    }

                    if (bankTransferConfirmed) {
                        setAddPaymentLoading(false);
                        updateEziDebitStateObject({
                            showPanel: false,
                            isConfirmation: false,
                        });
                        setShowPaymentOptionsPanel(false);

                        forIn(
                            localStorage,
                            (_value: string, objKey: string) => {
                                if (startsWith(objKey, storedInvoicesPrefix)) {
                                    localStorage.removeItem(objKey);
                                }
                            }
                        );

                        Modal.success({
                            title: 'Success',
                            content:
                                'Direct debit has been scheduled for payment.',
                            onOk: actionAfterSuccessRefreshPage,
                        });
                    } else {
                        const postUrl = get(response, 'PostUrl');
                        const postPayload = get(response, 'PostPayload');
                        const challenge = get(response, 'Challenge');
                        if (postUrl && postPayload) {
                            postRequestToEziDebitPaymentUrl(
                                postUrl,
                                postPayload
                            );
                        } else if (challenge) {
                            // Display the confirmation for bank transfer
                            setAddPaymentLoading(false);
                            updateEziDebitStateObject({
                                showPanel: true,
                                isConfirmation: true,
                            });
                        } else {
                            return Modal.error({
                                title: 'Error',
                                content: get(
                                    response,
                                    'Messages.0',
                                    'Something is wrong with the response!'
                                ),
                            });
                        }
                    }
                }
            )
        );
    };

    /**
     * Function that adds the ADVAM payment record and populates the link for iframe.
     * @param totalPayment
     * @param invoiceIds
     */
    const addADVAMPaymentRecord = (
        totalPayment: number,
        invoiceIds = tableSelection.selectedRowKeys
    ) => {
        setAddPaymentLoading(true);
        const totalPaymentRounded = getTotalPaymentRounded(totalPayment);
        const usedInvoiceIds = getInvoiceIds(invoiceIds);

        const clientReferenceUsed = getPaymentClientReference();

        const payload: AddPaymentForADVAMRequestPayload = {
            Result: paymentResults.UNCONFIRMED,
            PaidDate: formatDateToDateObjectUTC(
                moment(),
                undefined,
                true
            ).format(dateFormatYYYYMMDDTHHmmssDash),
            PaidAmount: totalPaymentRounded,
            InvoiceIds: usedInvoiceIds,
            CustomerId: get(paymentScheduleDetails, 'Customer.Id'),
            CompanyId: get(paymentScheduleDetails, 'Company.CompanyId'),
            CommunicationId: props.match.params.notificationId,
            ClientReference: clientReferenceUsed,
        };

        addCookieSettingForDelayFetch();

        dispatch(
            addPaymentForADVAMRequestAction(
                payload,
                (response: DynamicObject) => {
                    setAddPaymentLoading(false);
                    if (get(response, 'IsSuccess') === false) {
                        return Modal.error({
                            title: 'Error',
                            content: get(
                                response,
                                'Messages.0',
                                'Failed to collect payment information!'
                            ),
                        });
                    }

                    updateADVAMStateObject({
                        showPanel: true,
                        iframeURL: get(response, 'IFrameUrl'),
                    });
                }
            )
        );
    };

    /**
     * Function that navigates to the integrapay payment portal after payment record has been added.
     * @param totalPayment
     * @param invoiceIds
     */
    const navigateToIntegraPayPaymentUrl = (
        totalPayment: number,
        invoiceIds = tableSelection.selectedRowKeys
    ) => {
        const totalPaymentRounded = getTotalPaymentRounded(totalPayment);
        const usedInvoiceIds = getInvoiceIds(invoiceIds);
        const clientReferenceUsed = getPaymentClientReference();
        setAddPaymentLoading(true);

        const payload: AddPaymentForIntegraPayRequestPayload = {
            Result: paymentResults.UNCONFIRMED,
            PaidDate: formatDateToDateObjectUTC(
                moment(),
                undefined,
                true
            ).format(dateFormatYYYYMMDDTHHmmssDash),
            PaidAmount: totalPaymentRounded,
            InvoiceIds: usedInvoiceIds,
            CustomerId: get(paymentScheduleDetails, 'Customer.Id'),
            CompanyId: get(paymentScheduleDetails, 'Company.CompanyId'),
            CommunicationId: props.match.params.notificationId,
            ClientReference: clientReferenceUsed,
        };
        addCookieSettingForDelayFetch();

        dispatch(
            addPaymentForIntegraPayRequestAction(
                payload,
                (response: DynamicObject) => {
                    if (get(response, 'IsSuccess') === false) {
                        setAddPaymentLoading(false);

                        return Modal.error({
                            title: 'Error',
                            content: get(
                                response,
                                'Messages.0',
                                'Failed to collect payment information!'
                            ),
                        });
                    }

                    const integraPayPaymentUrl = get(response, 'RedirectUrl');

                    if (get(response, 'Redirect') && integraPayPaymentUrl)
                        window.location.href = integraPayPaymentUrl;
                }
            )
        );
    };

    /**
     * Function for processing the post request after EziDebit is hit
     * @param postUrl
     * @param postPayload
     */
    const postRequestToEziDebitPaymentUrl = (
        postUrl: string,
        postPayload: any
    ) => {
        updateEziDebitStateObject({
            isConfirmation: false,
        });

        postBypassCORS(postUrl, JSON.parse(postPayload));
    };
    const openCorpayPaymentPanel = () => {
        isCorpayClicked = true
        const { pathname, search } = props.location;
        const urlQueries = QueryString.parse(search);
        urlQueries.p = 'cp';
        const newUrlQueries = QueryString.stringify(urlQueries);

        const urlToGo = isEmpty(urlQueries)
            ? pathname
            : `${pathname}?${newUrlQueries}`;
        props.history.push(urlToGo);

    };

    const openCorpaySendInstructionPanel = (paymentId: string) => {
        setCorpaySendInstructionState({
            ...corpaySendInstructionState,
            showCorpaySendInstructionPanel:
                true,
            paymentId: paymentId
        });
    };

    /**
     * Function that populates the details section for `Pay Online (Western Union)` payment option
     */
    const populatePayByWesternUnionBodySection = () => {
        return (
            <div>
                <div className="mt-10 mb-10">
                    Pay by credit or debit card in over 100 currencies, via e-wallet and
                    open banking or directly into a local currency bank account.
                </div>
                <div className="ta-center mt-5">
                    {map(supportedWUPaymentProviders, (provider: any, idx) => (
                        <img
                            key={idx}
                            className="payment-provider-logo"
                            src={provider.logo}
                            alt={provider.alt}
                        />
                    ))}
                </div>
                <div>
                    <Button
                        block
                        type="primary"
                        disabled={!(paymentScheduleTotal > 0)}
                        onClick={onWUPayNowClick}
                        loading={isLoading}
                    >
                        Pay Now
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Function that populates the details section for `Pay with corpay` payment option
     */
    const populatePayByCorpayBodySection = () => {
        return (
            <div>
                <div className="ta-center mt-5">
                    <img
                        className="corpay-logo"
                        src={corpayLogo}
                        alt="Corpay"
                    />
                </div>
                <div className="mt-10 mb-10">
                    {get(paymentScheduleDetails, 'Company.Name')} has partnered with Corpay to provide you with a variety of currency options to pay your invoice with.
                </div>
                <div>
                    <Button
                        block
                        type="primary"
                        disabled={!(paymentScheduleTotal > 0)}
                        onClick={openCorpayPaymentPanel}
                        loading={isLoading}
                    >
                        Pay with Corpay
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Function that populates the details section for `Pay by Credit Card` payment option
     */
    const populatePayByCreditCardBodySection = (description: string) => {
        let defaultCardsAvailable = defaultCreditCardsAvailable;
        const creditcardPaymentSelected = get(
            paymentOptionsData,
            paymentOptionsDataMapping.CardPaymentSelectedCreditCards,
            []
        );
        if (!isEmpty(creditcardPaymentSelected)) {
            defaultCardsAvailable = creditcardPaymentSelected;
        }
        const defaultValue = (
            <div>
                {!isEmpty(description) ? (
                    <div className="card-custom-description">{parseToHTML(description)}</div>
                ) : (
                    <Fragment>                        
                        <div className="ta-center mt-20 mb-10">
                            {map(creditCardOptionsList, (ccol: DynamicObject) => {
                                if (includes(defaultCardsAvailable, ccol.value)) {
                                    return (
                                        <FontAwesome
                                            key={ccol.value}
                                            icon={['fab', ccol.icon]}
                                            className="mlr-2_5 fs-30 primary"
                                            style={iconStyle}
                                        />
                                    );
                                }
                                return undefined;
                            })}
                        </div>
                        <div>
                            <Button
                                block
                                type="primary"
                                disabled={!(paymentScheduleTotal > 0)}
                                onClick={onPayNowClick}
                                loading={isLoading}
                            >
                                Pay Now
                            </Button>
                        </div>
                    </Fragment>
                )}
            </div>
        );
        if (cardPaymentSystemSelected && overseasCardPaymentSystemSelected) {
            return defaultValue;
        } else {
            const isOverseas = !isNull(overseasCardPaymentSystemSelected);
            const cardPaymentSystemValueUsed = isOverseas
                ? overseasCardPaymentSystemSelected
                : cardPaymentSystemSelected;
            const prefixText = isOverseas ? 'Overseas' : '';

            if (
                cardPaymentSystemValueUsed === cardPaymentSystems.SELF_MANAGED
            ) {
                return parseToHTML(
                    get(
                        paymentOptionsData,
                        paymentOptionsDataMapping[
                        `${prefixText}CardPaymentSelfManagedDescription`
                        ]
                    )
                );
            } else {
                return defaultValue;
            }
        }
    };

    const generateSectionWithLoading = (
        sectionComponent: any,
        loadingWidth: number,
        indicatorValue?: any
    ) => {
        if ((!indicatorValue && isLoading) || notificationDetailsError) {
            return (
                <Skeleton
                    className="notification-skeleton"
                    active={!notificationDetailsError}
                    loading
                    paragraph={false}
                    title={{ width: loadingWidth }}
                />
            );
        } else {
            return sectionComponent;
        }
    };

    /**
     * Function that populates an error section using AntDesign's Empty component
     */
    const populateErrorEmptyComponent = () => (
        <Empty
            className="error-empty"
            image={
                <FontAwesome
                    icon={['far', 'frown']}
                    size="5x"
                    className="grey"
                />
            }
            description={populateErrorMessages()}
        />
    );

    /**
     * Handler for paying.
     */
    const routePaymentActionsHandler = (
        invoiceIds = tableSelection.selectedRowKeys,
        cardPaymentSelected?: string,
        isOverseas?: boolean,
        totalPaymentRemaining?: number,
        payByCreditCard?: boolean
    ) => {
        routePaymentActions(
            cardPaymentSelected,
            isOverseas,
            totalPaymentRemaining,
            payByCreditCard
        );
    };

    /**
     * Function called when refreshing the page after successful operation.
     */
    const actionAfterSuccessRefreshPage = () => {
        const { pathname, search } = props.location;
        const urlQueries = QueryString.parse(search);
        const usedRegion =
            toString(urlQueries[notificationsQueryString.REGION]) ||
            DEFAULT_REGION_NAME;
        const navigateUrl = `${window.location.origin.toString()}${pathname}?region=${usedRegion}`;

        window.location.href = navigateUrl;
    };

    /**
     * Function called for routing paymenta ctions.
     */
    const routePaymentActions = (
        cardPaymentSelected?: string,
        isOverseas?: boolean,
        totalPaymentRemaining?: number,
        payByCreditCard?: boolean
    ) => {
        const amountRemaining = !isUndefined(totalPaymentRemaining)
            ? totalPaymentRemaining
            : paymentScheduleTotal;

        if (amountRemaining > 0) {
            if (cardPaymentSelected) {
                return actionAfterPaymentOptionClick(
                    amountRemaining,
                    cardPaymentSelected,
                    isOverseas,
                    payByCreditCard
                );
            } else if (isEmailWesternUnionClicked) {
                return navigateToWesternUnionPaymentUrl(amountRemaining);
            } else {
                return processActionBasedOnPaymentGateway(amountRemaining);
            }
        }
    };

    /**
     * Function that generates the title for the modal shown during file link generation.
     */
    const generateFilePreparationTitle = () => {
        return 'Generating attachment link';
    };

    /**
     * Function that generates the title for the modal shown during file link generation.
     */
    const generateFilePreparationContent = () => {
        return 'A new tab that shows or downloads the attachment will be opened. Please wait. . .';
    };

    const getBankAccountNumberUsed = () => {
        const bankAccountNumber = get(
            paymentOptionsData,
            paymentOptionsDataMapping.BankPaymentAccountNumber
        );

        if (bankAccountNumber) {
            const customerCode = getCustomerCode();
            return replaceAllStrDynamic(
                bankAccountNumber,
                customerNumberPlaceholder,
                customerCode
            );
        } else {
            return '';
        }
    };

    /**
     * Function for populating the main content of this page. (Either an error or the page populated from fetched data).
     */
    const populateErrorOrSuccessComponent = () => {
        let bankBSBNumber: any = undefined;
        let bankAccountNumber: any = undefined;
        let bankSwiftCode: any = undefined;
        let bankReferenceInstructions: any = undefined;

        if (isBankPaymentEnabled) {
            bankBSBNumber = get(
                paymentOptionsData,
                paymentOptionsDataMapping.BankPaymentBSBNumber
            );
            bankAccountNumber = getBankAccountNumberUsed();
            bankSwiftCode = get(
                paymentOptionsData,
                paymentOptionsDataMapping.BankPaymentSwiftCode
            );
            bankReferenceInstructions = get(
                paymentOptionsData,
                paymentOptionsDataMapping.BankPaymentReferenceInstructions,
                'Your invoice number'
            );
        }

        /**
         * Function for populating the text in the table header section
         */
        const populateTableHeaderText = () => {
            const scheduleDueDate = get(
                paymentScheduleDetails,
                'PaymentPlanSchedule.LocalDueDateTime'
            );

            const helpingVerb =
                scheduleDueDate && moment().isAfter(moment(scheduleDueDate))
                    ? 'was'
                    : 'is';

            return (
                <h4>
                    A payment instalment {helpingVerb} due on{' '}
                    {scheduleDueDate
                        ? props.formatDateLocal(scheduleDueDate)
                        : '--'}{' '}
                    covering the below invoices. Please select from the provided
                    payment options to make the payment.
                </h4>
            );
        };

        const populateBrandingRow = () => {
            return (
                <Row type="flex" gutter={6} style={{
                    justifyContent: (() => {
                        const brandingLocation = get(customization, 'BrandingLocation') || defaultDisplay.BrandingLocation;
                        if (endsWith(brandingLocation, 'left')) return 'start';
                        if (endsWith(brandingLocation, 'centre')) return 'center';
                        if (endsWith(brandingLocation, 'right')) return 'end';
                        return undefined;
                    })(),
                    flexDirection: (() => {
                        const brandingLocation = get(customization, 'BrandingLocation') || defaultDisplay.BrandingLocation;
                        if (endsWith(brandingLocation, 'right')) return 'row-reverse';
                        return 'row';
                    })(),
                }}>
                    <Col className="pr-8">
                        <ImageWithLoading
                            hasPlaceholder
                            src={companyLogo}
                            style={{
                                height: 'auto',
                                width: 'auto',
                                maxHeight: 120,
                                maxWidth: 360,
                            }}
                            loading={
                                get(
                                    paymentScheduleDetails,
                                    'Company.LogoPath'
                                )
                                    ? false
                                    : isLoading
                            }
                            hasError={notificationDetailsError}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <Col span={24}>
                                {generateSectionWithLoading(
                                    <Title level={3}>
                                        {get(
                                            paymentScheduleDetails,
                                            'Company.Name'
                                        )}
                                    </Title>,
                                    250,
                                    get(
                                        paymentScheduleDetails,
                                        'Company.Name'
                                    )
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center-flex-i" span={24}>
                                <FontAwesome
                                    icon={['fas', 'phone-alt']}
                                    className="mr-10"
                                />

                                {generateSectionWithLoading(
                                    get(
                                        paymentScheduleDetails,
                                        'Company.PhoneNumber'
                                    ),
                                    200,
                                    get(
                                        paymentScheduleDetails,
                                        'Company.PhoneNumber'
                                    )
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center-flex-i" span={24}>
                                <FontAwesome
                                    icon={['fas', 'envelope']}
                                    className="mr-10"
                                />
                                {generateSectionWithLoading(
                                    get(
                                        paymentScheduleDetails,
                                        'Company.Email'
                                    ),
                                    200,
                                    get(
                                        paymentScheduleDetails,
                                        'Company.Email'
                                    )
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        };

        /**
         * Function that populates the card payment customization.
         */
        const populateCardPaymentOptions = () => {
            const cardPaymentCustomization = get(customization, 'CardPaymentCustomization');
            const cardPaymentCustomizationHeading = get(cardPaymentCustomization, 'Heading');
            const cardPaymentCustomizationDescription = get(cardPaymentCustomization, 'Description');

            return (
                <Card
                    className="card-custom-thin-title-centered pay-by-card-section"
                    title={ cardPaymentCustomizationHeading ? cardPaymentCustomizationHeading :
                        (isWesternUnionPaymentEnabled ? `Pay by ${countryCodeAlpha2 || ''} Credit Card` : "Pay by Credit Card")}
                >
                    {populatePayByCreditCardBodySection(cardPaymentCustomizationDescription)}
                </Card>
            );
        };

        /**
         * Function that populates the cheque payment customization.
         */
        const populateMailingChequePaymentOptions = () => {
            const mailingChequePaymentCustomization = get(customization, 'MailingChequePaymentCustomization');
            const mailingChequePaymentCustomizationHeading = get(mailingChequePaymentCustomization, 'Heading');
            const mailingChequePaymentCustomizationDescription = get(mailingChequePaymentCustomization, 'Description');

            return (
                <Card
                    className="card-custom-thin-title-centered"
                    title={mailingChequePaymentCustomizationHeading ? mailingChequePaymentCustomizationHeading : "Pay by Mail"}
                >
                    {!isEmpty(mailingChequePaymentCustomizationDescription) ? (
                        <div className="card-custom-description">{parseToHTML(mailingChequePaymentCustomizationDescription)}</div>
                    ) : (
                        <Fragment>
                            <div>
                                Make cheques payable to
                            </div>
                            <div>
                                {get(
                                    paymentScheduleDetails,
                                    'Company.Name'
                                )}
                            </div>
                            {address1 && (
                                <div>{address1}</div>
                            )}
                            {address2 && (
                                <div>{address2}</div>
                            )}
                            {completePostAddress && (
                                <div>
                                    {
                                        completePostAddress
                                    }
                                </div>
                            )}
                        </Fragment>
                    )}
                </Card>
            )
        };

        /**
         * Function that populates the bank deposit payment customization.
         */
        const populateBankDepositPaymentOptions = () => {
            const bankDepositPaymentCustomization = get(customization, 'BankPaymentCustomization');
            const bankDepositPaymentCustomizationHeading = get(bankDepositPaymentCustomization, 'Heading');
            const bankDepositPaymentCustomizationDescription = get(bankDepositPaymentCustomization, 'Description');
            
            return (
                <Card
                    className="card-custom-thin-title-centered"
                    title={bankDepositPaymentCustomizationHeading ? bankDepositPaymentCustomizationHeading : "Pay by Bank Deposit/EFT"}
                >
                    {!isEmpty(bankDepositPaymentCustomizationDescription) ? (
                        <div className="card-custom-description">{parseToHTML(bankDepositPaymentCustomizationDescription)}</div>
                    ): (
                        <Fragment>
                            <div>
                                Account Name:{' '}
                                {get(
                                    paymentOptionsData,
                                    paymentOptionsDataMapping.BankPaymentAccountName
                                )}
                            </div>
                            {bankBSBNumber && (
                                <div>
                                    {get(
                                        languagePack,
                                        'BankBSB',
                                        'BSB'
                                    )}
                                    : {bankBSBNumber}
                                </div>
                            )}
                            {bankAccountNumber && (
                                <div>
                                    {get(
                                        languagePack,
                                        'BankAccountNumber',
                                        'Account No'
                                    )}
                                    :{' '}
                                    {bankAccountNumber}
                                </div>
                            )}
                            {bankSwiftCode && (
                                <div>
                                    SWIFT code:{' '}
                                    {bankSwiftCode}
                                </div>
                            )}
                            {bankReferenceInstructions && (
                                <div>
                                    Reference
                                    instructions:{' '}
                                    {
                                        bankReferenceInstructions
                                    }
                                </div>
                            )}
                        </Fragment>
                    ) }
                </Card>
            )
        };

        return (
            <>
                <Row>
                    <Col>
                        {isTopBranding && <>
                            {populateBrandingRow()}
                            <hr className="themed-divider" />
                        </>}
                        <Row>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        {populateTableHeaderText()}
                                    </Col>
                                </Row>
                                <Row className="mb-10" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} sm={0} xs={0}>
                        <Table
                            className="themed-table"
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            loading={{
                                spinning: isLoading,
                                tip: 'Fetching details. Please wait. . .',
                            }}
                            locale={{
                                emptyText: notificationDetailsError ? (
                                    populateErrorEmptyComponent()
                                ) : (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                ),
                            }}
                        />
                    </Col>
                    <Col md={0} sm={24} xs={24}>
                        {generatePanelSection()}
                    </Col>
                </Row>
                <br />
                <Row className="mb-20">
                    <Col>
                        <Row gutter={6} type="flex" align="middle">
                            <Col xl={18} lg={18} md={18} sm={24} style={{overflowWrap: "anywhere"}}>
                                {populateInformUsInstruction()}
                            </Col>
                            <Col
                                className="mobile-inform-us"
                                xl={6}
                                lg={6}
                                md={6}
                                sm={24}
                            >
                                <Button
                                    type="primary"
                                    loading={isLoading}
                                    onClick={() => openInformUsPanel()}
                                    disabled={
                                        notificationDetailsError
                                    }
                                >
                                    {populateInformUsLabel()}
                                </Button>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" gutter={10}>
                            <Col>
                                <span className="h3-custom">
                                    Payment Instalment #
                                    {get(
                                        paymentScheduleDetails,
                                        'PaymentPlanSchedule.Number',
                                        '--'
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" gutter={10}>
                            <Col>
                                <span className="h3-custom">
                                    Scheduled Payment:{' '}
                                    {handleFormatCurrency(paymentScheduleTotal)}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(isMailingChequePaymentEnabled ||
                            isWesternUnionPaymentEnabled ||
                            isBankPaymentEnabled ||
                            cardPaymentSystemSelected ||
                            overseasCardPaymentSystemSelected ||
                            isLoading ||
                            notificationDetailsError) && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <h3>Payment Options</h3>
                                        </Col>
                                    </Row>
                                    {(isEmpty(paymentOptionsData) && isLoading) ||
                                        notificationDetailsError ? (
                                        <Row gutter={6}>
                                            <Col>
                                                <Skeleton
                                                    active={
                                                        !notificationDetailsError
                                                    }
                                                    loading
                                                    title={false}
                                                    paragraph={{ rows: 4 }}
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row gutter={6} type="flex">
                                            {isWesternUnionPaymentEnabled && (
                                                <Col
                                                    xl={6}
                                                    lg={8}
                                                    md={8}
                                                    xs={24}
                                                    sm={24}
                                                    className="mb-20"
                                                >
                                                    <Card
                                                        className="card-custom-thin-title-centered"
                                                        title="Pay Online"
                                                    >
                                                        {populatePayByWesternUnionBodySection()}
                                                    </Card>
                                                </Col>
                                            )}
                                            {isCorpayPaymentEnabled && corpayPaymentOptionStatus === 'Completed' ? (
                                                <Col
                                                    xl={6}
                                                    lg={8}
                                                    md={8}
                                                    xs={24}
                                                    sm={24}
                                                    className="mb-20"
                                                >
                                                    <Card
                                                        className="card-custom-thin-title-centered"
                                                        title="Pay Online"
                                                    >
                                                        {populatePayByCorpayBodySection()}
                                                    </Card>
                                                </Col>
                                            ) : ''}

                                            {(cardPaymentSystemSelected ||
                                                overseasCardPaymentSystemSelected) && (
                                                    <Col
                                                        xl={6}
                                                        lg={8}
                                                        md={8}
                                                        xs={24}
                                                        sm={24}
                                                        className="mb-20"
                                                    >
                                                        {populateCardPaymentOptions()}
                                                    </Col>
                                                )}

                                            {isMailingChequePaymentEnabled && (
                                                <Col
                                                    xl={6}
                                                    lg={8}
                                                    md={8}
                                                    xs={24}
                                                    sm={24}
                                                    className="mb-20"
                                                >
                                                    {populateMailingChequePaymentOptions()}
                                                </Col>
                                            )}

                                            {isBankPaymentEnabled && (
                                                <Col
                                                    xl={6}
                                                    lg={8}
                                                    md={8}
                                                    xs={24}
                                                    sm={24}
                                                    className="mb-20"
                                                >
                                                    {populateBankDepositPaymentOptions()}
                                                </Col>
                                            )}
                                        </Row>
                                    )}
                                </>
                            )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {isBottomBranding && <>
                            <hr className="themed-divider" />
                            {populateBrandingRow()}
                        </>}
                    </Col>
                </Row>
                <Suspense fallback={null}>
                    <NotificationDetailsAttachmentsPanel
                        visible={attachmentsState.showPanel}
                        closePanel={closeAttachmentsPanel}
                        itemDetails={
                            attachmentsState.selectedRecordForAttachment
                        }
                        onViewAttachment={handleViewFile}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <div ref={informUsPanelRef}>
                        <NotificationDetailsPanel
                            visible={informUsVisible}
                            closePanel={closeExtraQueryStringPanel}
                            invoices={paymentScheduleDetails.Invoices}
                            customer={paymentScheduleDetails.Customer}
                            company={paymentScheduleDetails.Company}
                            selectedRowKeys={tableSelection.selectedRowKeys}
                            selectionDisabled={true}
                        />
                    </div>
                </Suspense>
                <Suspense fallback={null}>
                    <NotificationPaymentOptionsPanel
                        visible={showPaymentOptionsPanel}
                        closePanel={closePaymentOptionsPanel}
                        companyInfo={get(paymentScheduleDetails, 'Company')}
                        totalPayment={paymentScheduleTotal}
                        onPaymentGatewayChoose={onPaymentGatewayChoose}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <NotificationEziDebitPaymentOptionsPanel
                        visible={eziDebitState.showPanel}
                        closePanel={closeEziDebitPaymentOptionsPanel}
                        totalPayment={paymentScheduleTotal}
                        invoices={paymentScheduleDetails.Invoices}
                        selectedRowKeys={tableSelection.selectedRowKeys}
                        onPaymentOptionChoose={onEziDebitPaymentOptionChoose}
                        isConfirmation={eziDebitState.isConfirmation}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <NotificationAdvamPaymentOptionsPanel
                        visible={advamState.showPanel}
                        closePanel={closeAdvamPaymentOptionsPanel}
                        totalPayment={paymentScheduleTotal}
                        invoices={paymentScheduleDetails.Invoices}
                        selectedRowKeys={tableSelection.selectedRowKeys}
                        iframeURL={advamState.iframeURL}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <div ref={corpayPaymentPanelRef}>
                        <NotificationCorpayPaymentAutoPanel
                            visible={
                                corpayPaymentState.showCorpayPaymentSelectAutoPanel
                            }
                            setCorpayPaymentState={setCorpayPaymentState}
                            closePanel={closeExtraQueryStringPanel}
                            currencies={corpayCurrencies}
                            totalPayment={paymentScheduleTotal}
                            company={paymentScheduleDetails.Company}
                            setCorpayInstructPaymentDetails={setCorpayInstructPaymentDetails}
                            corpayInstructPaymentDetails={corpayInstructPaymentDetails}
                            setSpotRateDetails={setSpotRateDetails}
                            spotRateDetails={spotRateDetails}
                        />
                    </div>
                </Suspense>
                <Suspense fallback={null}>
                    <div ref={corpaySendInstructionPanelRef}>
                        <NotificationCorpaySendInstructionPanel
                            visible={
                                corpaySendInstructionState.showCorpaySendInstructionPanel
                            }
                            setCorpaySendInstructionState={setCorpaySendInstructionState}
                            corpaySendInstructionState={corpaySendInstructionState}
                            setSpotRateDetails={setSpotRateDetails}
                            spotRateDetails={spotRateDetails}
                            paymentId={
                                corpaySendInstructionState.paymentId
                            }
                            company={paymentScheduleDetails.Company}
                            customer={paymentScheduleDetails.Customer}
                            sendPaymentInstructionDispatchAction={sendInstructionPaymentForCorpayRequestAction}
                            closePanel={closeExtraQueryStringPanel}
                        />
                    </div>
                </Suspense>
            </>
        );
    };

    if (notificationDetailsError) {
        primaryColorTheme = '#F2F2F2';
        contrastPrimaryColor = '#A9A9A9';
    }

    let paymentLoadingDisplayMessage =
        'Please wait while we collect the information required for the payment and re-direct you to the payment portal. . .';
    if (
        cardPaymentSystemSelected === cardPaymentSystems.EZIDEBIT ||
        cardPaymentSystemSelected === cardPaymentSystems.ADVAM || 
        isCorpayClicked == true
    ) {
        paymentLoadingDisplayMessage =
            'Please wait while we collect the information required for the payment. . .';
    }
    return (
        <Col className="notification-details-container">
            {/**
             * Inline style tag for dynamic child css based on company theme
             */}
            <style>
                {`
                         .themed-table table thead > tr > th, .themed-collapse .ant-collapse-header {
                             background-color: ${primaryColorTheme};
                             color: ${contrastPrimaryColor} !important;
                         }
     
                         .themed-table table thead > tr > th .ant-checkbox, .themed-collapse .ant-collapse-header .ant-checkbox {
                             border: 1px solid ${contrastPrimaryColor};
                             border-radius: 3px;
                         }
     
                         .themed-divider {
                             border-color: ${primaryColorTheme};
                         }
                     `}
            </style>

            <Row
                style={{
                    height: '100%',
                }}
            >
                <Col
                    style={{
                        padding: 20,
                        height: '100%',
                    }}
                >
                    {populateErrorOrSuccessComponent()}
                </Col>
            </Row>
            {preparingFileLink.loading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={generateFilePreparationTitle()}
                        modalVisible={preparingFileLink.loading}
                        displayMessage={generateFilePreparationContent()}
                    />
                </Suspense>
            )}

            {addPaymentLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={`Collecting payment information`}
                        modalVisible={addPaymentLoading}
                        displayMessage={paymentLoadingDisplayMessage}
                    />
                </Suspense>
            )}
        </Col>
    );
};

export default withDateFormatHandler(
    withNumberFormatHandler(NotificationDetails)
);
