import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import {
    all,
    call,
    fork,
    put,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
} from '../../config/config';
import queries from '../../graphql/queries.graphql';
import { DynamicObject } from '../../utils/commonInterfaces';
import { WorkflowActionTypes } from './types';
import { getWorkflowDefinitionErrorAction, getWorkflowDefinitionSuccessAction, saveWorkflowDefinitionConfigurationResponseAction } from './actions';

export const getWorflowDefinition = (state: ApplicationState) =>
    get(state, 'workflowDefinition');

export const getWorflowDefinitionConfigure = (state: ApplicationState) =>
    get(state.workflowDefinition.workflowDefinition, 'workflowDefinitionConfigure');

/**
 * Function called for getting the workflow step options for task editing.
 * @param param0
 */
function* handleGetWorkflowDefinitionRequest({ payload: sagaPayload }: any) {
    const errorMessage = 'Error fetching workflow definitions. Please try again later.';
    try {
        const { WorkflowId } = sagaPayload;
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_WORKFLOW_DEFINITION, {
                WorkflowId
            })
        );

        const workflowDefinition = get(res.data, 'GetWorkflowDefinition');

        if (workflowDefinition) {
            yield put(getWorkflowDefinitionSuccessAction(workflowDefinition));
        } else {
            yield put(getWorkflowDefinitionErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(getWorkflowDefinitionErrorAction([errorMessage]));
    }
}

/**
 * Function called for saving the workflow definition - talking to the API.
 */
function* handleSaveWorkflowDefinitionRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        yield call([API, 'post'], API_NAME, '/workflow/definition/update', {
            body: payload,
        });

        yield put(saveWorkflowDefinitionConfigurationResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
        // calls for queries if needed
    } catch (err) {
        yield put(saveWorkflowDefinitionConfigurationResponseAction());
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetWorkflowDefinitionRequest() {
    yield takeLatest(
        WorkflowActionTypes.GET_WORKFLOW_DEFINITION_REQUEST,
        handleGetWorkflowDefinitionRequest
    );
}

function* watchSaveWorkflowDefinitionRequest() {
    yield takeLatest(
        WorkflowActionTypes.SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST,
        handleSaveWorkflowDefinitionRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* workflowDefinitionSaga() {
    yield all([
        fork(watchGetWorkflowDefinitionRequest),
        fork(watchSaveWorkflowDefinitionRequest)
    ]);
}

export default workflowDefinitionSaga;
