/**
 * File responsible for the content when clicking `Cancel plan` in management pages.
 */

import { Button, Col, DatePicker, Drawer, Form, InputNumber, Modal, Row, Spin, Table, Tooltip } from 'antd';
import { cloneDeep, every, filter, findIndex, first, forEach, get, isEmpty, isEqual, map, sumBy } from 'lodash';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { getPopoverContainer, roundToDecimals } from '../../utils/commonFunctions';
import { PaymentPlanSchedule, PaymentPlanScheduleVM } from '../../store/paymentPlans/types';
import {
    getPaymentPlanDataRequestAction,
} from '../../store/paymentPlans/actions';
import {
    getPaymentPlanData
} from '../../store/paymentPlans/sagas';
import moment from 'moment-timezone';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import { useDispatch, useSelector } from 'react-redux';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { DynamicObject } from '../../utils/commonInterfaces';
import { paymentPlanScheduleState } from '../../constants/paymentPlansSortAndFilters';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { defaultGuidValue } from '../../constants/common';
import { ApplicationState } from '../../store';
import { naiveRound } from '../../utils/contentFunctions';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const FORM_FIELDS = {
    PAYMENTPLANINSTALMENTS: 'PaymentPlanInstalments',
    INSTALMENT_AMOUNT: 'InstalmentAmount',
    INSTALMENT_DUE_DATE: 'InstalmentDueDate',
};

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (isPaymentPlanEditedSuccessfully?: boolean) => void;
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly form?: any;
    readonly dispatchAction?: (payload: any, callback: any) => void;
    readonly isUsingCloudImportType: boolean;
    readonly paymentPlanId: string;
}

interface ICombinedProps extends IProps {
    valueChanges?: any;
    setValueChanges: (valueChanges?: any) => void;
}

const EditPaymentPlanPanel: React.FC<ICombinedProps> = ({
    containerRef,
    visible,
    closePanel,
    formatCurrency,
    formatToParts,
    form,
    dispatchAction, 
    valueChanges,
    setValueChanges,
    isUsingCloudImportType,
    paymentPlanId
}: ICombinedProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [paymentPlanInstalmentList, setPaymentPlanInstalmentList] = useState<Partial<PaymentPlanScheduleVM>[]>([]);
    const [originalPaymentPlanInstalments, setOriginalPaymentPlanInstalments] = useState<Partial<PaymentPlanScheduleVM>[]>([]);
    const [hasError, setHasError] = useState<boolean>(true);
    const { validateFields, resetFields } = form;
   
    /**
     * Listener on page load or if a new paymentPlan Id is selected.
     */
    const initializeDataByPaymentPlanId = () => {
        if (paymentPlanId) {
            dispatch(
                getPaymentPlanDataRequestAction(paymentPlanId, isUsingCloudImportType)
            );
        }
    };

    useEffect(initializeDataByPaymentPlanId, [paymentPlanId, isUsingCloudImportType]);

    const companyTimezone = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.Timezone')
    );
    const paymentPlanData = useSelector(getPaymentPlanData);
    const activePaymentPlanData = get(paymentPlanData, 'record');
    const { loading } = paymentPlanData;

    const changePaymentPlanInstalmentList = useMemo(() => (value: React.SetStateAction<Partial<PaymentPlanScheduleVM>[]>) => {
        setPaymentPlanInstalmentList(value);
        setValueChanges({
            [FORM_FIELDS.PAYMENTPLANINSTALMENTS]: true
        });
    }, [setValueChanges]);

    const instalmentsTableColums = [
        {
            title: 'Instalment',
            dataIndex: 'InstalmentNumber',
            width: '15%'
        },
        {
            title: 'Instalment Amount',
            dataIndex: 'InstalmentAmount',
            width: '24%'
        },
        {
            title: 'Remaining Amount',
            dataIndex: 'RemainingAmount',
            width: '18%'
        },
        {
            title: 'Paid Amount',
            dataIndex: 'PaidAmount',
            width: '15%'
        },
        {
            title: 'Instalment Due Date',
            dataIndex: 'InstalmentDueDate',
            width: '28%'
        }
    ];

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    /**
     * Function that init the setPaymentPlanInstalmentList.
     */
    const initPaymentPlanInstalmentList = () => {
        if (activePaymentPlanData && activePaymentPlanData.PaymentPlanSchedules) {
            let paymentPlanScheduleVMs = cloneDeep(activePaymentPlanData.PaymentPlanSchedules);

            if(paymentPlanScheduleVMs && paymentPlanScheduleVMs.length > 0) {
                paymentPlanScheduleVMs.forEach((pp: PaymentPlanScheduleVM) => {
                    pp.PaidAmount = roundToDecimals(pp.TotalAmount - pp.AmountRemaining);
                });
    
                setOriginalPaymentPlanInstalments(cloneDeep(paymentPlanScheduleVMs));
                setPaymentPlanInstalmentList(paymentPlanScheduleVMs);
            }
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    useEffect(initPaymentPlanInstalmentList, [activePaymentPlanData]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                if (dispatchAction) {
                    dispatch(
                        dispatchAction(
                           paymentPlanInstalmentList,
                           editPaymentPlanScheduleResponseModal
                        )
                    );
                } else {
                    if (closePanel) closePanel(true);
                }
            }
        });
    };

    /**
     * Function responsible for showing the response modal after editing the payment plan schedule.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const editPaymentPlanScheduleResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Payment plan schedule is updated successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to update payment plan schedule!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return {
            amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
            currency: first(map(
                filter(
                    (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                ),
                p => p.value
            ))
        };
    };

    const validatePaymentPlanSchedule = useMemo(() => (paymentPlanSchedule: Partial<PaymentPlanScheduleVM>) => {
        const validationResult: {
            valid: boolean,
            warningMessages: string[]
        } = { valid: true, warningMessages: [] };
        
        if (formHasChange()) {
            const localDueDateTime = moment(paymentPlanSchedule.LocalDueDateTime).format(dateFormatDDMMMYYYYSpace);
            const allowToEditPaymentPlanInstalmentList = paymentPlanInstalmentList.filter(p => (p.State === paymentPlanScheduleState.OPEN ||
                                                        p.State === paymentPlanScheduleState.NEW || p.State === paymentPlanScheduleState.PENDING) &&
                                                        (paymentPlanSchedule.AmountRemaining && paymentPlanSchedule.AmountRemaining > 0));

            if (!paymentPlanSchedule.TotalAmount || paymentPlanSchedule.TotalAmount <= 0) {
                validationResult.valid = false;
                validationResult.warningMessages.push('Instalment has no amount!');
            }

            if (paymentPlanSchedule.TotalAmount && paymentPlanSchedule.PaidAmount &&
                paymentPlanSchedule.State !== paymentPlanScheduleState.NEW && 
                (paymentPlanSchedule.TotalAmount < paymentPlanSchedule.PaidAmount)) {
                validationResult.valid = false;
                validationResult.warningMessages.push('Instalment amount can not be less than paid amount!');
            }
            
            if (!paymentPlanSchedule.LocalDueDateTime) {
                validationResult.valid = false;
                validationResult.warningMessages.push('Instalment has no due date!');
            }
            
            if (allowToEditPaymentPlanInstalmentList.some(p => p.Number && paymentPlanSchedule.Number && parseInt(p.Number.toString()) > parseInt(paymentPlanSchedule.Number.toString()) && 
                                                    moment(moment(p.LocalDueDateTime).format(dateFormatDDMMMYYYYSpace)).isSameOrBefore(localDueDateTime))) {
                validationResult.valid = false;
                validationResult.warningMessages.push(`Schedule's due date should not overlap the others!`);
            }

            if (allowToEditPaymentPlanInstalmentList && allowToEditPaymentPlanInstalmentList.length > 0) {
                const totalPaymentScheduleAmount = naiveRound(sumBy(allowToEditPaymentPlanInstalmentList, p => p.TotalAmount || 0), 2);
                
                if (activePaymentPlanData && totalPaymentScheduleAmount !== activePaymentPlanData.AmountRemaining) {
                    validationResult.valid = false;
                    validationResult.warningMessages.push(`Instalment amount total must equal remaining payment plan amount!`);
                }
            }
        }

        return validationResult;
    }, [paymentPlanInstalmentList, companyTimezone]);

    const validateForm = useMemo(() => (options?: {
        success?: (values: any) => void
    }) => {
        validateFields((err: any, values: DynamicObject) => {
            const validPaymentPlanSchedules = paymentPlanInstalmentList.length > 0 ? every(paymentPlanInstalmentList, p => validatePaymentPlanSchedule(p).valid) : true;
            
            if (!err && validPaymentPlanSchedules && paymentPlanInstalmentList.length > 0) {
                setHasError(false);
                if (options && options.success) options.success(values);
            } else {
                setHasError(true);
            }
        });
    }, [validateFields, paymentPlanInstalmentList]);

    useEffect(() => {
        if (!isEmpty(valueChanges)) {
            validateForm();
        }
    }, [valueChanges, validateForm]);


    const formHasChange = () => {
        if (!activePaymentPlanData) return false;

        return !isEqual(originalPaymentPlanInstalments, paymentPlanInstalmentList);
    };

    const updatePaymentPlanSchedule = (paymentPlanSchedule: Partial<PaymentPlanScheduleVM>, newPaymentPlanSchedule: Partial<PaymentPlanScheduleVM>) => {
        changePaymentPlanInstalmentList(list => {
            const updatedPaymentPlanScheduleIdx = findIndex(list, schedule => schedule === paymentPlanSchedule);
            if (updatedPaymentPlanScheduleIdx !== -1) {
                list.splice(updatedPaymentPlanScheduleIdx, 1, newPaymentPlanSchedule);
            }
            
            return [...list];
        });
    };


    const populatePaymentInstalmentListTableDataSourceItem = (paymentPlanSchedule: PaymentPlanScheduleVM, index: number) => {
        const { currency } = handleFormatCurrency(paymentPlanSchedule.AmountRemaining);
        const { warningMessages } = validatePaymentPlanSchedule(paymentPlanSchedule);
        const isAllowToEdit = ((paymentPlanSchedule.State === paymentPlanScheduleState.OPEN || 
                                paymentPlanSchedule.State === paymentPlanScheduleState.PENDING) &&
                                paymentPlanSchedule.AmountRemaining > 0) ||
                                paymentPlanSchedule.State === paymentPlanScheduleState.NEW;

        const isAllowToDelete = paymentPlanSchedule.State === paymentPlanScheduleState.NEW;

        return {
            Id: paymentPlanSchedule.Id,
            InstalmentNumber: paymentPlanSchedule.Number,
            InstalmentAmount: (<>
                {`${currency} `}
                <InputNumber
                    placeholder="Instalment amount"
                    min={1}
                    style={{ width: 150 }}
                    value={paymentPlanSchedule.TotalAmount}
                    readOnly={!isAllowToEdit}
                    onChange={(value) => {
                        const newPaymentPlanSchedule = {
                            ...paymentPlanSchedule,
                            TotalAmount: value || 0,
                            AmountRemaining: paymentPlanSchedule.State === paymentPlanScheduleState.NEW ? (value || 0) : paymentPlanSchedule.AmountRemaining
                        };
                        updatePaymentPlanSchedule(paymentPlanSchedule, newPaymentPlanSchedule);
                    }}
                />
            </>),
            RemainingAmount: `${currency} ${paymentPlanSchedule.AmountRemaining}`,
            PaidAmount: `${currency} ${paymentPlanSchedule.PaidAmount}`,
            InstalmentDueDate: (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {!isAllowToEdit ? moment(paymentPlanSchedule.LocalDueDateTime).format(dateFormatDDMMMYYYYSpace) : 
                            <DatePicker
                                format={dateFormatDDMMMYYYYSpace}
                                placeholder="Instalment due date"
                                style={{ maxWidth: 250 }}
                                defaultValue={paymentPlanSchedule.LocalDueDateTime && moment(paymentPlanSchedule.LocalDueDateTime) || null}
                                value={moment(paymentPlanSchedule.LocalDueDateTime)}
                                onChange={(value) => {
                                    const newPaymentPlanSchedule = {
                                        ...paymentPlanSchedule,
                                        LocalDueDateTime: (value || moment()).format(dateFormatDDMMMYYYYSpace)
                                    };
                                    updatePaymentPlanSchedule(paymentPlanSchedule, newPaymentPlanSchedule);
                                }}
                            />}
                    </div>
                    {(isAllowToEdit && warningMessages.length > 0) && <Tooltip
                        title={warningMessages.map((message, idx) => <div key={idx}>{message}</div>)}
                        placement="topRight">
                        <FontAwesome
                            icon={['fas', 'info-circle']}
                            style={{ color: '#ff5d00' }}
                            className="fs-18 mr-5"
                        />
                    </Tooltip>}
                    {isAllowToDelete && <div 
                        className="fs-16 cursor-p-div" 
                        onClick={handleDeletePaymentPlanInstalment(paymentPlanSchedule)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>}
                </div>
            </>)
        }
    };

    const handleAddPaymentPlanInstalment = () => {
        changePaymentPlanInstalmentList(list => {
            return [...list, {
                Id: defaultGuidValue,
                State: paymentPlanScheduleState.NEW,
                Number: paymentPlanInstalmentList.length + 1,
                PaidAmount: 0,
                AmountRemaining: 0,
                LocalDueDateTime: moment(new Date()).format(dateFormatDDMMMYYYYSpace)
            }];
        });
    };

    const handleDeletePaymentPlanInstalment = useCallback((paymentPlanInstalment: Partial<PaymentPlanScheduleVM>) => () => {
        changePaymentPlanInstalmentList(list => {
            const paymentPlanInstalmentIdx = findIndex(list, inv => inv === paymentPlanInstalment);
            if (paymentPlanInstalmentIdx !== -1) {
                list.splice(paymentPlanInstalmentIdx, 1);
            }
            
            return [...list];
        })
    }, [changePaymentPlanInstalmentList]);

    const isSaveBtnDisabled =  submitLoading  || hasError || !formHasChange();

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24} className="mb-10">
                        <h4>Payment plan remaining amount: {activePaymentPlanData && handleFormatCurrency(activePaymentPlanData.AmountRemaining).amountDisplay}</h4>
                        <p>Remaining total installment amount must equal remaining payment plan amount!</p>
                        <h4>Total amount to pay is: {activePaymentPlanData && handleFormatCurrency(activePaymentPlanData.TotalAmount).amountDisplay}</h4>
                    </Col>
                </Row>
                <Row>
                    <h4>The payment plan will be created the following instalments</h4>
                    <Col span={24}>
                        <Table
                            rowKey="InstalmentNumber"
                            className="app-pl-instalments-table"
                            columns={instalmentsTableColums}
                            dataSource={map(paymentPlanInstalmentList, populatePaymentInstalmentListTableDataSourceItem)}
                            pagination={false}
                            bordered={true}
                        />
                    </Col>
                    <Col span={24} className="ta-right">
                        <div>You can add up to 30 instalments</div>
                        <Button
                            type="link"
                            onClick={handleAddPaymentPlanInstalment}
                            style={{ padding: 0 }}
                            disabled={paymentPlanInstalmentList.length >= 30}
                        >
                            <FontAwesome
                                icon={['fa', 'plus']}
                                className="mr-8"
                            />
                            <span>Add other instalment</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Drawer
            title="Edit Payment Plan"
            width={1000}
            onClose={handleClosePanel}
            visible={visible}
            className="edit-payment-plan-panel"
            closable={false}
            maskClosable={false}
        >
            <Spin spinning={loading}>
                {activePaymentPlanData && (<Row>
                    <Col>
                        <div>{populatePanelContent()}</div>
                        <br />
                        <Row>
                            <Col className="ta-right" span={24}>
                                <Button
                                    className="mr-8"
                                    type="primary"
                                    onClick={handleSubmitForm}
                                    disabled={isSaveBtnDisabled}
                                >
                                    Save
                                </Button>
                                <Button className="buttonGrey mr-8" onClick={handleClosePanel}>Cancel</Button>
                            </Col>
                        </Row>
                    </Col>
                    {submitLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle="Updating payment plan schedule"
                                modalVisible={submitLoading}
                                displayMessage="Please wait while updating payment plan schedule . . ."
                                containerRef={containerRef}
                            />
                        </Suspense>
                    )}
                </Row>)}
            </Spin>
        </Drawer>
    );
};

const EditPaymentPlanPanelWrapper: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const EditPaymentPlanPanelForm = Form.create({
            name: 'edit-payment-plan-panel-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(EditPaymentPlanPanel);

        return withNumberFormatHandler(
            EditPaymentPlanPanelForm
        );
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default withAccountingSystemHandler(EditPaymentPlanPanelWrapper);

