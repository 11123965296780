/**
 * Component that populates the item for each row in PaymentPlans page.
 */

import { Checkbox, Col, Row } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { get, includes, isEqual, isNull } from 'lodash';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatDMMMYYYYSpace } from '../../constants/dateFormats';
import {
    getPaymentPlanFirstOpenSchedule,
    paymentPlanState,
} from '../../constants/paymentPlansSortAndFilters';
import '../../pages/sales/sales.less';
import { getNumberOfDaysBeforeRecentCommentTurnedOff } from '../../store/companies/sagas';
import { getPaymentPlanSelectedId } from '../../store/paymentPlans/sagas';
import { PaymentPlan } from '../../store/paymentPlans/types';
import { getCompanyName, getIfIsLg } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import AvatarNameInitialsComponent from '../common/AvatarNameInitialsComponent';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { generateCommentItemWithType } from '../common/SharedExportComponents';

interface IProps {
    readonly item: PaymentPlan;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly daysFromToday?: (date: any, dateFormat?: string) => number | null;
}
const PaymentPlanItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatCurrency,
    formatDateLocal,
    daysFromToday,
}: IProps) => {
    const firstRowContainerRef = useRef<any>(null);
    const selectedId = useSelector(getPaymentPlanSelectedId);
    const numberOfDaysBeforeRecentCommentTurnedOff: number = useSelector(getNumberOfDaysBeforeRecentCommentTurnedOff);

    const { Id, Customer, ConversationLine, State, PaymentPlanSchedules } =
        item;

    const firstOpenPaymentPlanSchedule =
        getPaymentPlanFirstOpenSchedule(PaymentPlanSchedules);

    const firstOpenDueDateTime = get(
        firstOpenPaymentPlanSchedule,
        'LocalDueDateTime'
    );

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Function called when clicking on the `latest comment` icon.
     * @param e
     */
    const viewCommentsSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'conversation');
    };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : null;
    };

    /**
     * Function for getting the days overdue of a certain date.
     * @param date - date to base the overdue number of days
     */
    const getDaysOverdue = (date: any) => {
        if (daysFromToday) {
            return daysFromToday(date);
        } else {
            return null;
        }
    };

    let itemColor = 'green';
    let amountRemainingColor = 'green';
    const daysOverdue = getDaysOverdue(firstOpenDueDateTime);
    const isOverDue = !isNull(daysOverdue) && daysOverdue > 0;
    const amtRemaining = get(item, 'AmountRemaining');

    if (State === paymentPlanState.PENDING) {
        itemColor = 'orange';
        amountRemainingColor = 'orange';
        // itemIcon = ['far', 'circle'];
    } else if (State === paymentPlanState.COMPLETED || State === paymentPlanState.CANCELLED) {
        itemColor = 'settled';
        amountRemainingColor = amtRemaining > 0 ? 'red' : 'settled';
    } else if (State === paymentPlanState.REJECTED) {
        itemColor = 'settled';
        amountRemainingColor = 'settled';
    } else {
        if (amtRemaining <= 0) {
            amountRemainingColor = "settled";
        }
        if (isOverDue) {
            itemColor = "red";
            if (amtRemaining > 0) {
                amountRemainingColor = "red";
            }
        }
    }

    const isLg = getIfIsLg();

    // let secondRowContainerHeight: any = 'auto';
    let firstRowContainerHeight: any = 'auto';

    /**
     * Generate item icon based on paymentPlan type - default for now
     */
    const generateItemIcon = () => {
        let iconUsed = (
            <FontAwesome
                icon={['fas', 'money-bill-alt']}
                className={itemColor}
                size="2x"
                transform="shrink-8 left-5"
            />
        );

        return (
            <div
                className="fa-layers fa-fw fa-2x"
                style={{ fontSize: isLg ? 24 : undefined }}
            >
                <FontAwesome
                    icon={['far', 'circle']}
                    className={itemColor}
                    size="2x"
                    transform="left-3"
                />
                {iconUsed}
            </div>
        );
    };

    if (isLg) {
        firstRowContainerHeight = get(
            firstRowContainerRef,
            'current.clientHeight'
        )
            ? get(firstRowContainerRef, 'current.clientHeight') - 2
            : 40;
    }

    const customerComment = get(Customer, 'ConversationLine');

    /**
     * Function for populating the icon group from row item.
     * Defined to easily use in different screen size layouts.
     */
    const iconGroupComponent = (
        <Col
            className="contact-icons-container pa-0"
            style={{ width: 55, float: 'right' }}
        >
            <ButtonGroup className="button-link-icons">
                {ConversationLine &&
                    generateCommentItemWithType(
                        ConversationLine,
                        'paymentPlan',
                        numberOfDaysBeforeRecentCommentTurnedOff,
                        viewCommentsSectionDrawer
                    )}
                {customerComment &&
                    generateCommentItemWithType(
                        customerComment,
                        'customer',
                        numberOfDaysBeforeRecentCommentTurnedOff,
                        viewCommentsSectionDrawer
                    )}
            </ButtonGroup>
        </Col>
    );

    const assignedUser = get(item, 'AssignedUser');
    const fullName = assignedUser
        ? get(assignedUser, 'GivenName') + ' ' + get(assignedUser, 'FamilyName')
        : undefined;

    /**
     * Function for populating the payment due section.
     */
    const populatePaymentDueSection = () => {
        let paymentDueText = 'Next payment due';
        let usedDate = firstOpenDueDateTime;
        if (State === paymentPlanState.COMPLETED) {
            paymentDueText = 'Plan completed on';
            usedDate = get(item, 'LocalClosedDateTime');
        } else if (State === paymentPlanState.CANCELLED) {
            paymentDueText = 'Plan cancelled on';
            usedDate = get(item, 'LocalClosedDateTime');
        } else if (State === paymentPlanState.REJECTED) {
            paymentDueText = 'Plan rejected on';
            usedDate = get(item, 'LocalRejectedDateTime');
        } else if (State === paymentPlanState.PENDING) {
            paymentDueText = 'Pending approval since';
            usedDate = get(item, 'LocalRequestedDateTime');
        } else if (isOverDue) {
            paymentDueText = 'Next payment overdue';
        }
        return (
            <b className={itemColor}>
                {paymentDueText}{' '}
                {usedDate
                    ? formatDateLocal(usedDate, null, dateFormatDMMMYYYYSpace)
                    : '--'}
            </b>
        );
    };

    /**
     * Function for populating invoice count text covered by the payment plan
     */
    const populateInvoiceCountText = () => {
        const invoiceIds = get(item, 'InvoiceIds', []);
        const invoiceIdsLen = invoiceIds.length;
        return `${invoiceIdsLen} invoice${invoiceIdsLen > 1 ? 's' : ''}`;
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Row type="flex">
                    <Col className="fx-1">
                        <Row
                            className="fx-center-xl center-flex-i pr-8 h-100"
                            gutter={{ xxl: 10, xl: 10, lg: 20 }}
                        >
                            <Col
                                className={`h-100 ${isLg ? 'pr-0' : ''}`}
                                xxl={2}
                                xl={3}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                            >
                                <Row
                                    className="h-100"
                                    type="flex"
                                    align={isLg ? 'top' : 'middle'}
                                >
                                    <Col
                                        xl={12}
                                        // xl={24}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                    >
                                        <div
                                            className="ta-center center-flex-all"
                                            style={{
                                                height: isLg
                                                    ? 'auto'
                                                    : firstRowContainerHeight,
                                            }}
                                        >
                                            <Checkbox
                                                checked={includes(
                                                    selectedRowKeys,
                                                    Id
                                                )}
                                                onClick={handleCheckboxClick}
                                            />
                                        </div>
                                    </Col>
                                    <Col
                                        xl={12}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                        className="ta-center"
                                    >
                                        <div
                                            className="ta-center center-flex-all"
                                            style={{ minHeight: 56 }}
                                        >
                                            {generateItemIcon()}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xxl={22}
                                xl={21}
                                lg={22}
                                md={22}
                                sm={22}
                                xs={22}
                                className="h-100"
                            >
                                <Row
                                    className="h-100"
                                    type="flex"
                                    align="middle"
                                >
                                    <Col className="fx-1">
                                        <Row type="flex">
                                            <Col
                                                // xxl={4}
                                                className={`flx-3 mxw-100`}
                                            >
                                                <div>
                                                    <b className="ws-nw va-top">
                                                        #{get(item, 'Number')}
                                                    </b>
                                                </div>
                                                <div>
                                                    {get(
                                                        Customer,
                                                        'CustomerCode'
                                                    )}
                                                </div>
                                            </Col>
                                            <Col className={`flx-7 mxw-100`}>
                                                <div>
                                                    <b>
                                                        Payment plan for{' '}
                                                        {getCompanyName(
                                                            Customer
                                                        )}{' '}
                                                        covering{' '}
                                                        {populateInvoiceCountText()}
                                                    </b>
                                                </div>
                                                <div>
                                                    {getCompanyName(Customer)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col
                                        className="ta-right"
                                        style={{ width: isLg ? 280 : 315 }}
                                    >
                                        <Row
                                            gutter={30}
                                            type="flex"
                                            justify={
                                                isLg ? 'end' : 'space-between'
                                            }
                                            className="ma-0"
                                        >
                                            {!isLg && iconGroupComponent}
                                            <Col
                                                className="pr-0 pl-5"
                                                style={{ maxWidth: 260 }}
                                            >
                                                <div>
                                                    <b
                                                        className={
                                                            amountRemainingColor
                                                        }
                                                    >
                                                        Amount remaining{' '}
                                                        {handleFormatCurrency(
                                                            get(
                                                                item,
                                                                'AmountRemaining',
                                                                0
                                                            )
                                                        )}
                                                    </b>
                                                </div>
                                                <div>
                                                    {populatePaymentDueSection()}
                                                </div>
                                                {isLg && iconGroupComponent}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            {/* End of paymentPlan item */}
                        </Row>
                    </Col>
                    <Col className="ta-center" style={{ width: 80 }}>
                        <AvatarNameInitialsComponent
                            fullName={fullName}
                            size={50}
                            isDefault={false}
                            showTooltip
                            fontSize={22}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
        prevProps.addCommentDrawerVisible === nextProps.addCommentDrawerVisible
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(PaymentPlanItemComponent)),
    arePropsEqual
);
