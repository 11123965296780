/**
 * File for the `Tasks` widget.
 */

import { Spin, Table } from 'antd';
import { clone, find, forEach, get, includes, isEmpty, isNumber, map, sum, isUndefined, set } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { widgetDisplayTypeValues, populatePayloadForOrganisationRegionalWidgets, tableNumberFormatter } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getDashboardTasksCountRequestAction } from '../../store/dashboards/actions';
import { TaskCountStateName } from '../../store/dashboards/types';
import { dashboardBypassAPIFetch, getDateFilterValues, checkIfEmailIsValid } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    taskFieldIndicator
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    dateSelectOptions
} from '../../constants/invoicesSortAndFilters';

interface ResultObject {
    WorkflowId: string;
    States: string[];
}

interface IProps {
    widgetDetails: DynamicObject;
    readonly preview?: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => JSX.Element;
    readonly functionRefObj?: any;
}
const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#F44336',
    '#9C27B0',
    '#FFEB3B',
    '#795548',
    '#8BC34A',
    '#263238',
];

const TasksWidget: React.FC<IProps> = ({
    widgetDetails,
    preview,
    isOrgView,
    organisationCurrenciesAll,
    formatNumber,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    let widgetRef = useRef<any>(null);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    /**
     * Variable indicator for when the company is using customer workflow or not.
     */
    const usingCustomerWorkflow: boolean = useSelector(
        (state: ApplicationState) =>
            get(
                state.companies.selectedUserCompany,
                'Company.UsingCustomerWorkflow'
            )
    );

    const [widgetState, setWidgetState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
    });

    let currencyValueUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        const region = widgetDetails.Region;
        const currencySelectedParsed = region
            ? find(organisationCurrenciesAll, ['Name', region])
            : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateTableStateObject = (widgetStateObject: {}) => {
        setWidgetState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    const convertTasksWorkflowsToObject = (tasksWorkflows: string[]): ResultObject[] => {
        const workflowMap: { [key: string]: string[] } = {};
        if (!isUndefined(tasksWorkflows)) {
            tasksWorkflows.forEach((workflow) => {
                const [state, workflowId] = workflow.split('---');
                if (workflowMap[workflowId]) {
                    workflowMap[workflowId].push(state);
                } else {
                    workflowMap[workflowId] = [state];
                }
            })
        };

        return Object.keys(workflowMap).map((workflowId) => ({
            WorkflowId: workflowId,
            States: workflowMap[workflowId],
        }));
    }

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const customFieldFilters: DynamicObject = {};
        let InvoiceNumber: string = '';
        let AssignedUserId: any = undefined;
        let AssignedEmailAddress: any = undefined;
        let Customer: string = '';
        let CustomerCountry: string = '';
        let CustomerState: string = '';
        let amountFilters: any = {
            AmountType: '',
            AmountValue: 0,
            AmountOperator: '',
        };
        let actionDateFilters: any = {
            value: undefined,
            From: {},
            To: {},
            Last: undefined,
            Next: undefined
        }
        let isRangeTypeFromLast: boolean = false;
        let isRangeTypeToLast: boolean = false;
        let ActionDateMin: any = undefined;
        let ActionDateMax: any = undefined;
        let WorkflowFilters: DynamicObject = {};

        actionDateFilters.value = get(widgetDetails, "TaskField---ActionDate--DateType");

        if (get(widgetDetails, "TaskField---ActionDate--RangeTypeFrom") === "Last") {
            isRangeTypeFromLast = true;
        }

        if (get(widgetDetails, "TaskField---ActionDate--RangeTypeTo") === "Last") {
            isRangeTypeToLast = true;
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );
                if (
                    (usingCustomerWorkflow &&
                        customFieldType !== CUSTOM_FIELD_TYPES.INVOICE) ||
                    !usingCustomerWorkflow
                ) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            if (includes(wdKey, taskFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "Amount--AmountType")) {
                    amountFilters.AmountType = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountOperator")) {
                    amountFilters.AmountOperator = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountValue")) {
                    amountFilters.AmountValue = wdValue;
                }
                else if (includes(wdKey, "CustomerCountry")) {
                    CustomerCountry = wdValue;
                }
                else if (includes(wdKey, "CustomerState")) {
                    CustomerState = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "AssignedUserId")) {
                    if (checkIfEmailIsValid(wdValue)) {
                        AssignedEmailAddress = wdValue
                    }
                    else if (includes(wdKey, "AssignedUserId--UserId")) {
                        AssignedUserId = wdValue;
                    }
                }
                else if (includes(wdKey, "InvoiceNumber")) {
                    InvoiceNumber = wdValue;
                }
                else if (includes(wdKey, "ActionDate")) {
                    if (actionDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            actionDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            actionDateFilters.To = moment(wdValue);
                        }

                        const { minDate, maxDate } = getDateFilterValues(actionDateFilters);
                        ActionDateMin = minDate;
                        ActionDateMax = maxDate;
                    }
                    else if (actionDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From") && isRangeTypeFromLast) {
                            actionDateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Next--From") && !isRangeTypeFromLast) {
                            actionDateFilters.From.Next = wdValue;
                        }
                        else if (includes(wdKey, "Last--To") && isRangeTypeToLast) {
                            actionDateFilters.To.Last = wdValue;
                        }
                        else if (includes(wdKey, "Next--To") && !isRangeTypeToLast) {
                            actionDateFilters.To.Next = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(actionDateFilters);
                    ActionDateMin = minDate;
                    ActionDateMax = maxDate;
                }
            }
        });

        const taskTypes = map(
            get(widgetDetails, 'tasksTypes'),
            (taskType: string) => parseInt(taskType)
        );

        WorkflowFilters = convertTasksWorkflowsToObject(get(widgetDetails, 'tasksWorkflows'))

        let payload: DynamicObject = {
            filters: {
                DisplayName: Customer,
                CustomerCode: Customer,
                InvoiceNumber,
                ...amountFilters,
                ActionDateMin: !isEmpty(ActionDateMin) ? ActionDateMin : undefined,
                ActionDateMax: !isEmpty(ActionDateMax) ? ActionDateMax : undefined,
                CustomerCountry,
                CustomerState,
                AssignedUserId,
                AssignedEmailAddress,
                WorkflowFilters,
                ...customFieldFilters,
            },
            Type: sum(taskTypes),
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
            });
        }
        dispatch(
            getDashboardTasksCountRequestAction(
                payload,
                isOrgView,
                (tasksCount: TaskCountStateName[]) => {
                    if (unmountedRef.current) return;
                    const formattedDataSource = map(
                        tasksCount,
                        (tc: TaskCountStateName) => ({
                            ...tc,
                            Count: tc.Count,
                        })
                    );

                    formattedDataSource.sort((a, b) => b.Count - a.Count);
                    
                    const usedDataSource = clone(formattedDataSource);

                    updateTableStateObject({
                        dataSource: usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);


    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return formatNumber(value);
        } else {
            return value;
        }
    };

    /**
   * Function that sorts the order for values inside a tooltip.
   * @param tooltipPayload
   */
    const tooltipItemSorter = (tooltipPayload: any) => {
        if (tooltipPayload.dataKey === blueValueKey) {
            return 1;
        }
        return 0;
    };

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            StateName,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        // const sx = cx + (outerRadius + 0) * cos;
        // const sy = cy + (outerRadius + 10) * sin;
        // const sy = cy + (outerRadius + 0) * sin;
        // const mx = cx + (outerRadius + 0) * cos;
        // const my = cy + (outerRadius + 30) * sin;
        // const my = cy + (outerRadius + 0) * sin;
        // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        // const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        // return `${StateName}: ${(percent * 100).toFixed(2)}%`;
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                {/* <path
                    d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                    stroke={fill}
                    fill="none"
                /> */}
                {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {StateName}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${formatNumber(percent * 100, 1, localeUsed)}%`}
                </text>
                {/* <text
                    x={ex + (cos >= 0 ? 1 : -1) * 2}
                    y={ey}
                    textAnchor={textAnchor}
                    fill="#333"
                >
                    {StateName}
                </text>
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    dy={18}
                    textAnchor={textAnchor}
                    fill="#999"
                >
                    {`${(percent * 100).toFixed(1)}%`}
                </text> */}
            </g>
        );
    };

    let blueValueKey = 'Count';

    const { loading: stateLoading, dataSource: stateDataSource } = widgetState;

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');

        if (displayView === widgetDisplayTypeValues.TABLE) {
            const columns = [
                {
                    title: 'Type',
                    dataIndex: 'StateName',
                },
                {
                    title: 'Count',
                    dataIndex: 'Count',
                },

            ];

            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'StateName') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={columns}
                    dataSource={tableNumberFormatter(stateDataSource, formatNumber)}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="StateName"
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART || displayView === undefined) {
            const pieChartData = clone(stateDataSource);

            const filteredData = pieChartData.filter((dataPoint: any) => dataPoint["Count"] > 0);

            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="Count"
                                nameKey="StateName"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <BarChart
                            data={stateDataSource}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 15,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                type="category"
                                dataKey="StateName"

                            />

                            <YAxis
                                type="number"
                                dataKey="Count"
                            />
                            <Tooltip
                                formatter={tooltipFormatter}
                                itemSorter={tooltipItemSorter}
                            />
                            {preview && <Legend />}
                            <Bar
                                dataKey={blueValueKey}
                                stackId="a"
                                fill="#0088fe"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Spin>
            );
        }
    };

    return (
        <div className="tasks-widget-container h-100" ref={widgetRef}>
            {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(TasksWidget);
